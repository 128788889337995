import React from 'react';
import './sign-in.styles.scss';
import { Auth } from '../../amplify/amplify.utils';
import { withRouter } from 'react-router-dom';

import FormInput from '../form-input/form-input.component';
import CustomButton from '../custom-button/custom-button.component';

interface SignInProps {
  history: any;
  match: any;
  location: any;
}

interface SignInState {
  email: string;
  password: string;
  error: string;
  user: any;
  newPassword: boolean;
}

class SignIn extends React.Component<SignInProps, SignInState> {

  constructor(props: SignInProps) {
    super(props);

    this.state = {
      email: '',
      password: '',
      error: '',
      user: null,
      newPassword: false
    }
  }

  handleSignIn = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const amplify = Auth();

    this.setState({
      error: ''
    });

    amplify.SignIn({ username: this.state.email, password: this.state.password })
      .then(({ user, signedIn, newPassword }: any) => {

        if (signedIn) {
          this.setState({
            email: '',
            password: ''
          });

          this.props.history.push('/');

          return;
        }

        if (newPassword) {
          this.setState({
            email: '',
            password: '',
            user,
            newPassword
          });
        }

      })
      .catch((err) => {
        console.warn('Log in error:', err);

        this.setState({
          error: err
        });
      });
  };

  handleNewPassword = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const amplify = Auth();

    this.setState({
      error: ''
    });

    const { user, password } = this.state;

    amplify.ConfirmNewPassword({ user, newPassword: password })
      .then(({ user }: any) => {
        console.log('User signed in:', user);

        this.setState({
          email: '',
          password: ''
        });

        this.props.history.push('/');

      })
      .catch((err) => {
        console.warn('Log in error:', err);

        this.setState({
          error: err
        });
      });
  };

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;

    if (name === 'password') {
      this.setState({ password: value });
      return;
    }

    if (name === 'email') {
      this.setState({ email: value });
      return;
    }
  };

  render() {
    let error: any = null;

    if (this.state.error) {
      error = <div className='error'>{this.state.error}</div>
    }

    const signIn = (
      <div className='sign-in'>
        <form onSubmit={this.handleSignIn}>
          <FormInput
            type='email'
            name='email'
            value={this.state.email}
            handleChange={this.handleChange}
            label='email'
            required={true}
          />
          <FormInput
            type='password'
            name='password'
            value={this.state.password}
            handleChange={this.handleChange}
            label='password'
            required={true}
          />

          {error}

          <div className='buttons'>
            <CustomButton type='submit' colour='secondary' size='lg' block={true}>Sign in</CustomButton>
          </div>
        </form>
      </div>
    );

    const newPassword = (
      <div className='sign-in'>
        <form onSubmit={this.handleNewPassword}>
          <FormInput
            type='password'
            name='password'
            value={this.state.password}
            handleChange={this.handleChange}
            label='new password'
            required={true}
          />

          {error}

          <CustomButton type='submit' value='Submit Form'>Change password</CustomButton>
        </form>
      </div>
    );

    return this.state.newPassword ? newPassword : signIn;
  }
}

export default withRouter(SignIn);
