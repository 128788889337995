import React, { Fragment } from 'react';
import { Button, Form } from 'react-bootstrap';
import useInputState from '../../hooks/useInputState';

interface NameForm {
  onChange: Function;
}

const NameForm = ({ onChange: callback }: NameForm) => {
  const [name, updateName] = useInputState('');
  const handleSubmit = () => {
    callback(name);
  }

  return (
    <Fragment>
      <h1 className='title'>Join a session</h1>
      <h4>Please enter your name</h4>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId='name'>
          <Form.Label>Name</Form.Label>
          <Form.Control type='text' placeholder='Participant name' value={name} name='name' onChange={updateName}/>
          <Form.Text className='text-muted'>
            Please enter you participant name.
          </Form.Text>
        </Form.Group>
        <Button variant='primary' size='lg' block={true} onClick={handleSubmit}>Start participating</Button>
      </Form>
    </Fragment>
  );
}

export default NameForm;
