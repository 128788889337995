import React, { useContext } from 'react';
import {
  SortableContainer,
  SortableElement,
  SortableHandle
} from 'react-sortable-hoc';
import CategoryItem from './category-item';
import { Button } from 'react-bootstrap';
import { faGripLines } from '@fortawesome/free-solid-svg-icons/faGripLines';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './category-widget.css';
import { BoardContext } from '../../contexts/BoardContext';

const CategoryWidget = () => {
  const { categories, categoriesAdd, categoriesRemove, categoriesReorder } = useContext(BoardContext)

  const DragHandle = SortableHandle(() => <div className={'item-drag'}><FontAwesomeIcon icon={faGripLines}/></div>);

  const SItem = SortableElement(({value}) => (
    <div key={value.id} className={'CategoryItem'}>
      <DragHandle />
      <CategoryItem
        category={value}
        remove={categoriesRemove}
      />
    </div>
  ));

  const SContainer = SortableContainer(({children}) => {
    return <ul>{children}</ul>;
  });

  return (
    <>
      <SContainer onSortEnd={categoriesReorder} useDragHandle={true}>
        {categories.map((value, index) => (
          <SItem key={value.id} index={index} value={value} />
        ))}
      </SContainer>
      <Button
        type='button'
        className='mb-2'
        onClick={() => categoriesAdd()}
      >
        <FontAwesomeIcon icon={faPlus}/>
      </Button>
    </>
  );
};

export default CategoryWidget;
