import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import ListGroup from 'react-bootstrap/ListGroup';
import moment from 'moment';

import Spinner from '../../components/spinner/spinner.component';
import CustomButton from '../../components/custom-button/custom-button.component';
import { useLazyQuery } from 'react-apollo';
import { Q_SESSION_LIST } from '../../graphql/queries';
import Log from '../../libs/log';

const log = Log({ source: 'SectionHost' });

interface homeMenuProps {
  match: any;
  history: any;
}

const HostHome = ({ match, history }: homeMenuProps) => {
  const [getSessionsList, { loading, data, error }] = useLazyQuery(Q_SESSION_LIST);

  useEffect(() => {
    log.debug({ action: 'SectionHost:listSessions:req', data: { status: null } });
    getSessionsList({ variables: {} });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onHostingStart = async () => {
    history.push(`${match.url}session`);
  };

  const onHostingContinue = ({ id }: { id: string }) => {
    console.log(`clicked continue with ${id}`);
    history.push(`${match.url}session/${ id }/host`);
  };

  const items = data && data.sessionList && data.sessionList.items;

  return (
    <div className='Host-Home'>
      <CustomButton onClick={onHostingStart} size='lg' block={true}>{'Start hosting'}</CustomButton>
      <br/>
      {
        loading ? <Spinner/> : null
      }
      {
        !loading && !error && items ? (
          <ListGroup>
            { items.map((m) =>  (
              <ListGroup.Item
                key={m.id}
                onClick={() => onHostingContinue({ id: m.id })}
              >
                {moment(m.modified).format('MMM DD HH:mm')} - {m.name}
              </ListGroup.Item>
            )) }
          </ListGroup>
          ) :
          null
      }
      {
        error ? <p>Could not get your sessions</p> : null
      }
    </div>
  );
};

export default withRouter(HostHome);
