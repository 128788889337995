import React from 'react';
import { Session } from '../../typings';
import { Col, Row, Form } from 'react-bootstrap';
import Portlet from '../Portlet/Portlet';
import QRCodeComponent from '../qrcode/qrcode.component';

interface ShareSessionDetailsProps {
  session: Session
}
const ShareSessionDetails = ({ session }: ShareSessionDetailsProps) => {
  const participateUrl = `${window.location.origin}/session/${session.id}/participate`;
  return (
    <Portlet title='Participate on your own device'>
      <Row className='justify-content-center'>
        <Col md={10}>
          <h6>Participant code:</h6>
          <h1 className='mb-5 pb-10'>{session.ref}</h1>
          <h6>Share link:</h6>
          <Form.Control plaintext={true} readOnly={true} defaultValue={participateUrl} />
        </Col>
        <Col>
          <div className='h-100 row align-items-center'>
            <QRCodeComponent link={participateUrl}/>
          </div>
        </Col>
      </Row>
    </Portlet>
  )
}

export default ShareSessionDetails;
