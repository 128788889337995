import React, { createContext, useState } from 'react';
import useToggle from '../hooks/useToggle';
// import Log from '../libs/log';

type DebugContextType = {
  debugging: boolean;
  scopes: { [t: string]: boolean; };
  toggleScope(scope: string): void;
  toggleDebugging(): void;
};
type DebugContextProps = {
  children: React.ReactNode;
};

// const log = Log({ source: 'contexts:debug' });

export const DebugContext = createContext({} as DebugContextType);

export function DebugProvider({ children }: DebugContextProps): any {
  const [debugging, toggleDebugging] = useToggle(false);
  const [scopes, setScopes] = useState<{ [t: string]: boolean }>({});

  const toggleScope = (scope: string) => {
    setScopes({
      ...scopes,
      scope: !scopes[scope]
    });
  }

  return (
    <DebugContext.Provider
      value={{
        debugging,
        scopes,
        toggleDebugging,
        toggleScope
      }}
    >
      {children}
    </DebugContext.Provider>
  )
}

export const withDebugContext = (Component: any) => (props: any) => (
  <DebugContext.Consumer>
    {value => <Component debugContext={value} {...props} />}
  </DebugContext.Consumer>
);
