import React, { useContext } from 'react';
import { ListGroup } from 'react-bootstrap';
import { Note } from '../../typings';
import Debugger from '../../components/debug/debugger';
import { SessionContext } from '../../contexts/SessionContext';
import { BoardContext } from '../../contexts/BoardContext';

type Props = {
  notes: Note[];
  showName?: boolean;
}

const NoteList = (props: Props) => {
  const { participants } = useContext(SessionContext);
  const { categoryMap } = useContext(BoardContext);

  const mapText = (note: Note): string => {
    const name = props.showName && `${(participants.find(f => f.id === note.participant.id) || { name: 'anonymous' }).name}: `;
    const category = note.group && ` (${categoryMap(note.group).name})`;
    return `${name || ''}${note.description}${category || ''}`;
  }

  return (
    <ListGroup>
      <Debugger name='props' value={props} />
      <Debugger name='participants' value={participants} />
      {
        props.notes
          .map(m => <ListGroup.Item key={m.id}>{mapText(m)}</ListGroup.Item>)
      }
    </ListGroup>
  );
};

export default NoteList;
