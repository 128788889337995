import React, { useContext, useState } from 'react';
import Spinner from '../../components/spinner/spinner.component';
import { Link } from 'react-router-dom';
import { Auth } from '../../amplify/amplify.utils';
import { SessionContext } from '../../contexts/SessionContext';
import { SidebarContext } from '../../contexts/SidebarContext';
import Avatar from '../avatar/avatar';
import { AuthContext } from '../../contexts/AuthContext';
import {
  faCaretDown,
  faCheck,
  faClipboard,
  faPowerOff,
  faShareSquare,
  faCheckSquare,
  faSquare
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useToggle from '../../hooks/useToggle';
import QRCodeComponent from '../qrcode/qrcode.component';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { AppScopes } from '../../shared/enums';
import { DebugContext } from '../../contexts/DebugContext';

const Header = () => {
  const { isAuthenticated, user, authenticating: loading, hasScope } = useContext(AuthContext);
  const { toggleSidebar } = useContext(SidebarContext);
  const { currentSession } = useContext(SessionContext);
  const { toggleDebugging, debugging } = useContext(DebugContext);
  const [copiedUrl, setCopiedUrl] = useState<string>();
  const [menuOpen, toggleMenuOpen] = useToggle(false);
  const [shareOpen, toggleShareOpen] = useToggle(false);
  const auth = Auth();

  if (loading) {
    return (<Spinner height={'20px'}/>);
  }

  /*if (error) {
    return (<p>Error!</p>);
  }*/

  return (
    <div>
      <header className='navbar page-header whiteHeader navbar-expand-lg'>
        <ul className='nav flex-row mr-auto'>
          <li className='nav-item'>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a className='nav-link sidenav-btn h-lg-down' onClick={toggleSidebar}>
              <span className='navbar-toggler-icon'/>
            </a>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a className='nav-link sidenav-btn h-lg-up' onClick={toggleSidebar}>
              <span className='navbar-toggler-icon'/>
            </a>
          </li>
        </ul>

        <ul className='nav flex-row order-lg-2 ml-auto nav-icons'>
          {/* Debug toggle */}
          {hasScope({ scope: AppScopes.DEBUG }) && (
            <li className='nav-item'>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a className='nav-link' onClick={toggleDebugging}>
                <FontAwesomeIcon icon={debugging ? faCheckSquare : faSquare}/>
                <span className='ml-1'>Debug</span>
              </a>
            </li>
          )}

          {/* Share information */}
          {currentSession && currentSession.ref && (
            <li className='nav-item dropdown'>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a className='nav-link' onClick={toggleShareOpen}>
                <FontAwesomeIcon icon={faShareSquare}/>
                <span className='ml-1'>Share</span>
              </a>
              <div
                className={shareOpen
                  ? 'dropdown-menu dropdown-menu-lg dropdown-menu-right no-padding show'
                  : 'dropdown-menu dropdown-menu-lg dropdown-menu-right no-padding'}
              >
                <div className='dropdown-header-top flex d-flex align-items-center'>
                  <span className='d-flex flex text-dark dropdown-title'>Session information</span>
                </div>
                <div className='px-3 py-3'>
                  <strong>Title:</strong> {currentSession.name}<br/>
                  {/* Participant key */}
                  <Form.Group>
                    <Form.Label>
                      <strong>Participant key:</strong>
                    </Form.Label>
                    <InputGroup className='mb-3'>
                      <Form.Control readOnly={true} value={currentSession.ref}/>
                      <InputGroup.Append>
                        <CopyToClipboard
                          text={currentSession.ref}
                          onCopy={() => setCopiedUrl('key')}
                        >
                          <Button variant='outline-primary'>
                            {copiedUrl !== 'key' && <FontAwesomeIcon icon={faClipboard}/>}
                            {copiedUrl === 'key' && <FontAwesomeIcon icon={faCheck}/>}
                          </Button>
                        </CopyToClipboard>
                      </InputGroup.Append>
                    </InputGroup>
                  </Form.Group>

                  {/* View URL */}
                  <Form.Group>
                    <Form.Label>
                      <strong>Viewer URL:</strong>
                    </Form.Label>
                    <InputGroup className='mb-3'>
                      <Form.Control readOnly={true} value={`${window.location.origin}/#/session/${currentSession.id}/present`}/>
                      <InputGroup.Append>
                        <CopyToClipboard
                          text={`${window.location.origin}/#/session/${currentSession.id}/present`}
                          onCopy={() => setCopiedUrl('view')}
                        >
                          <Button variant='outline-primary'>
                            {copiedUrl !== 'view' && <FontAwesomeIcon icon={faClipboard}/>}
                            {copiedUrl === 'view' && <FontAwesomeIcon icon={faCheck}/>}
                          </Button>
                        </CopyToClipboard>
                      </InputGroup.Append>
                    </InputGroup>
                  </Form.Group>

                  {/* Participant URL */}
                  <Form.Group>
                    <Form.Label>
                      <strong>Participant URL:</strong>
                    </Form.Label>
                    <InputGroup className='mb-3'>
                      <Form.Control readOnly={true} value={`${window.location.origin}/#/session/${currentSession.id}/participate`}/>
                      <InputGroup.Append>
                        <CopyToClipboard
                          text={`${window.location.origin}/#/session/${currentSession.id}/participate`}
                          onCopy={() => setCopiedUrl('participate')}
                        >
                          <Button variant='outline-primary'>
                            {copiedUrl !== 'participate' && <FontAwesomeIcon icon={faClipboard}/>}
                            {copiedUrl === 'participate' && <FontAwesomeIcon icon={faCheck}/>}
                          </Button>
                        </CopyToClipboard>
                      </InputGroup.Append>
                    </InputGroup>
                  </Form.Group>
                  <span className='my-2'><strong>QR code</strong></span><br/>
                  <QRCodeComponent link={`${window.location.origin}/#/session/${currentSession.id}`}/>
                </div>
              </div>
            </li>
          )}

          {/* Sign in */}
          {!isAuthenticated && (
            <li className='nav-item align-items-center'>
              <Link className='nav-link option' to='/sign-in'>Sign in</Link>
            </li>
          )}
          {isAuthenticated && (
            <li className='nav-item dropdown user-dropdown align-items-center'>
              <div>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a className='nav-link' onClick={toggleMenuOpen}>
                <span className='user-states'>
                  <Avatar name={user && user.email.split('@')[0] as any}/>
                </span>
                  <span className='ml-2 h-lg-down'>
                  Hi, {user && user.email.split('@')[0]} <FontAwesomeIcon icon={faCaretDown}/>
                </span>
                </a>
                <div
                  className={menuOpen ? 'dropdown-menu dropdown-menu-right show' : 'dropdown-menu dropdown-menu-right'}
                >
                  <div className='p-3 pt-0 b-b mb-5'>
                      <span className='mb-0 d-block font300 text-title fs-1x'>
                        Hi <span className='font700'>host/participant/viewer</span>
                      </span>
                    <span className='fs12 mb-0 text-muted'>Plan: Free</span>
                  </div>
                  {/*<a className='dropdown-item' href='#'><i className='icon-User'/>My Profile</a>
                    <a className='dropdown-item' href='#'><i className='icon-Newspaper'/> Tasks</a>
                    <a className='dropdown-item' href='#'>
                      <i className='icon-Speach-Bubble4 '/>
                      Messages <span className='badge badge-text text-primary-active bg-primary-light ml-2'>5 New</span>
                    </a>
                    <div className='dropdown-divider'/>
                    <a className='dropdown-item' href='#'>
                      <i className='icon-Gear'/>
                      Settings<span className='badge badge-text text-danger-active bg-danger-light ml-2'>Update</span>
                    </a>
                    <div className='dropdown-divider'/>*/}
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a className='dropdown-item' onClick={() => auth.SignOut()}><FontAwesomeIcon icon={faPowerOff}/> Sign
                    out</a>
                </div>
              </div>
            </li>
          )}
        </ul>
        {/*<div className='collapse navbar-collapse search-collapse' id='navbarToggler'>
          <form className='form-inline ml-1'>
            <button className='no-padding bg-trans border0' type='button'><i className='icon-Magnifi-Glass2'/>
            </button>
            <input className='form-control border0' type='search' placeholder='Search...' aria-label='Search'/>
          </form>
        </div>*/}
      </header>
    </div>
  );
};

export default Header;
