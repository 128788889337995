import { useState } from 'react';

function useToggle(initialVal = false): [boolean, any] {
  // call useState, "reserve piece of state"
  const [state, setState] = useState(initialVal);
  const toggle = () => {
    setState(!state);
  };

  // return piece of state AND a function to toggle it
  return [state, toggle];
}

export default useToggle;
