import React, { createContext } from 'react';
import useToggle from '../hooks/useToggle';

type SidebarContextType = {
  sidebarCollapsed: boolean;
  toggleSidebar: (any) => void;
};
type SidebarContextProps = {
  children: React.ReactNode;
};

export const SidebarContext = createContext({} as SidebarContextType);

export function SidebarProvider({ children }: SidebarContextProps): any {
  const [sidebarCollapsed, toggleSidebar] = useToggle(true);

  const values = {
    sidebarCollapsed,
    toggleSidebar
  };

  return (
    <SidebarContext.Provider value={values}>
      {children}
    </SidebarContext.Provider>
  );
}
