import React from 'react';

import './form-input.styles.scss';

export interface FormInputProps {
  handleChange: any;
  type: string;
  name: string;
  label: string;
  value: string;
  required?: boolean;
}

const FormInput = ({ handleChange, label, ...otherProps }: FormInputProps) => (
  <div className='form-group'>
    {
      label ?
        (<label className={`${otherProps.value.length ? 'shrink' : ''} form-input-label`}>
          {label}
        </label>) :
        null
    }
    <input
      className='form-control form-control-lg'
      onChange={handleChange}
      {...otherProps}
    />
  </div>
);

export default FormInput;
