import React, { useContext } from 'react';
import { Button, Form } from 'react-bootstrap';
import { BlockPicker } from 'react-color';
import useToggle from '../../hooks/useToggle';
import {
  faTimes
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './category-item.css';
import { BoardCategory } from '../../typings';
import { BoardContext } from '../../contexts/BoardContext';

type Props = {
  category: BoardCategory;
  remove(props: { id: string }): void;
}

const CategoryItem = ({ category, remove }: Props) => {
  const { categoryUpdate } = useContext(BoardContext);
  const [showColorPicker, toggleShowColorPicker] = useToggle(false)

  const update = (newCategory: { color?: string, name?: string }) => {
    categoryUpdate({
      ...category,
      ...newCategory
    });
  }

  const handleChangeComplete = (color) => {
    toggleShowColorPicker();
    // console.log('change complete', color);
    update({ color: color.hex })
  };

  return (
    <Form inline={true}>
      <div className={'CategoryItem'}>
        <span onClick={toggleShowColorPicker} style={{ color: category.color }}>{category.color}</span>

        <Form.Label htmlFor='categoryNameInput' srOnly={true}>
          Name
        </Form.Label>
        <Form.Control
          id='categoryNameInput'
          className='mb-2 mr-sm-2'
          type='name'
          placeholder='Name'
          value={category.name}
          name='name'
          onChange={(e) => update({ name: e.target.value })}
        />

        <Button
          type='button'
          variant='outline-danger'
          className='mb-2'
          onClick={() => remove(category)}
        >
          <FontAwesomeIcon icon={faTimes}/>
        </Button>
      </div>

      {
        showColorPicker
        && (
          <div className={'color-picker'}>
            <BlockPicker
              color={category.color}
              onChangeComplete={handleChangeComplete}
            />
          </div>
        )
      }

    </Form>
  )
};

export default CategoryItem;
