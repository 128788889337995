import React, { createContext, useContext } from 'react';
import { dimensionsPropsType } from './utils';

import './Chart.css'

const ChartContext = createContext({});
export const useChartDimensions = () => useContext(ChartContext)

interface ChartProps {
  dimensions: dimensionsPropsType;
  children?: any;
}

const Chart = ({ dimensions = {
  width: 800,
  height: 600
}, children }: ChartProps) => {

  // TODO: calculate width and height for dimentions

  return (
    <ChartContext.Provider value={dimensions}>
      <svg
        className='Chart'
        width={dimensions.width}
        height={dimensions.height}
      >
        <g
          transform={`translate(${dimensions.marginLeft}, ${dimensions.marginTop})`}
        >
          {children}
        </g>
      </svg>
    </ChartContext.Provider>
  )
}

export default Chart
