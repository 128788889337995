import gql from 'graphql-tag';

export const Q_GET_SESSION_USER = gql`query CurrentUser
  {
    currentUser @client {
      sub
      username
    }
  }
`;

export const Q_GET_PARTICIPANT = gql`query Participant
  {
    participant @client {
      id
      name
      sessionId
      status
    }
  }
`;

export const Q_GET_CURRENT_SESSION = gql`query CurrentSession
  {
    currentSession @client {
      id
      ref
      boards {
        id
        status
      }
    }
  }
`;

export const Q_SESSION_LIST = gql`query sessionList(
  $limit: Int,
  $nextToken: String,
  $filter: SessionFilter
) {
  sessionList(
    limit: $limit,
    nextToken: $nextToken,
    filter: $filter
  ) {
    nextToken
    items {
      id
      modified
      ref
      name
      created
      status
    }
  }
}`;

export const Q_SESSION = gql`query session(
  $sessionId: ID!
) {
  session(
    sessionId: $sessionId
  ) {
    id
    created
    modified
    status
    ref
    presentedBoard
  }
}`;

export const Q_SESSION_BOARDS = gql`query boards(
  $sessionId: ID!
) {
  boardList(
    filter: { sessionId: $sessionId }
  ) {
    items {
      id
      created
      modified
      status
      name
      sessionId
      template
      order
      groupingCriteria{
        id
        name
        order
        color
      }
    }
  }
}`;

export const Q_SESSION_PARTICIPANTS = gql`query session(
  $sessionId: ID!
) {
  participants(
    sessionId: $sessionId
  ) {
    id
    created
    modified
    status
    name
  }
}`;

export const Q_SESSION_BY_REF = gql`query sessionByRef(
  $ref: String!
) {
  sessionByRef(ref: $ref) {
    id
    created
    modified
    status
    ref
    presentedBoard
  }
}`;

export const Q_NOTES = gql`
  query Notes($boardId: ID!) {
    notes(boardId: $boardId) {
      id
      boardId
      status
      created
      modified
      description
      group
      tags
      participant {
        id
      }
    }
  }`;
