import debugCore from 'debug';

const BASE = 'rt';
const COLOURS = {
  silly: '#9c4489',
  verbose: '#639283',
  debug: '#619365',
  info: '#a1aa6c',
  warn: '#936840',
  error: '#a0453d'
}; // choose better colours :)

export interface LogOptions {
  source: string;
}

export interface LogStructure {
  action: string;
  message?: string;
  data?: any;
  error?: Error;
}

interface GenerateLog extends LogStructure {
  level: string;
}

const Log = ({ source }: LogOptions) => {
  const generateMessage = ({ level, action, message, error, data }: GenerateLog) => {

    // Set the prefix which will cause debug to enable the message
    const namespace = `${BASE}:${source}:${level}`;
    const createDebug = debugCore(namespace);

    // Set the colour of the message based on the level
    createDebug.color = COLOURS[level];

    const logObject: any = {};
    if (message) {
      logObject.message = message;
    }
    if (error) {
      logObject.error = error;
    }
    if (data) {
      logObject.data = data;
    }

    createDebug(action, logObject);
  };

  const silly = (log: LogStructure) => {
    return generateMessage({ ...log, level: 'silly' });
  };

  const verbose = (log: LogStructure) => {
    return generateMessage({ ...log, level: 'verbose' });
  };

  const debug = (log: LogStructure) => {
    return generateMessage({ ...log, level: 'debug' });
  };

  const info = (log: LogStructure) => {
    return generateMessage({ ...log, level: 'info' });
  };

  const warn = (log: LogStructure) => {
    return generateMessage({ ...log, level: 'warn' });
  };

  const error = (log: LogStructure) => {
    return generateMessage({ ...log, level: 'error' });
  };

  return Object.freeze({
    silly,
    verbose,
    debug,
    info,
    warn,
    error
  })
};

export default Log;
