import React from 'react';
import './Home.scss';

import Sections from './Sections';

const Home = () => (
  <div className='homePage'>
    <Sections />
  </div>
);

export default Home;
