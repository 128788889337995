import React from 'react';
import './custom-button.styles.scss';

export interface CustomButtonProps {
  children: any;
  onClick?: any;
  type?: 'button' | 'submit' | undefined;
  disabled?: boolean;
  value?: string;
  colour?: string;
  size?: string;
  block?: boolean;
}

const CustomButton = ({ children, colour, size, block, ...otherProps }: CustomButtonProps) => {
  let classNames = `btn btn-${size || 'md'} btn-${colour || 'primary'} ${block ? 'btn-block': ''}`;

  return (
    <button className={classNames} {...otherProps} >
      {children}
    </button>
  )
};

export default CustomButton;

