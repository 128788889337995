import React, { Fragment, useContext } from 'react';
import { SessionContext } from '../../contexts/SessionContext';
import { useHistory } from 'react-router-dom';
import { Button, Form } from 'react-bootstrap';
import useInputState from '../../hooks/useInputState';

interface SessionCreateProps {

}

const SessionCreate = (props: SessionCreateProps) => {
  const { createSession } = useContext(SessionContext);
  const [name, updateName] = useInputState('');

  let history = useHistory();

  const onHostingStart = async () => {
    const session: any = await createSession({ name });
    console.log('hosting started', session);
    history.push(`session/${ session.id }/host`);
  };

  return (
    <Fragment>
      <h1 className='title'>Start a session</h1>
      <h4>Please enter a session name</h4>
      <Form onSubmit={onHostingStart}>
        <Form.Group controlId='name'>
          <Form.Label>Name</Form.Label>
          <Form.Control type='text' placeholder='Session name' value={name} name='name' onChange={updateName}/>
          <Form.Text className='text-muted'>
            Please enter a session name.
          </Form.Text>
        </Form.Group>
        <Button
          variant='primary'
          size='lg'
          block={true}
          onClick={onHostingStart}
        >
          Start the session
        </Button>
      </Form>
    </Fragment>
  );
};

export default SessionCreate;
