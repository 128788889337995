import React, { Fragment, useContext } from 'react';
import { Button, Form } from 'react-bootstrap';
import { BoardUpdate as BoardInput } from '../../typings';
import useInputState from '../../hooks/useInputState';
import { BoardContext } from '../../contexts/BoardContext';
import CategoryWidget from '../../components/category-widget/category-widget';

const BoardEdit = () => {
  const { editBoard, currentBoard, toggleEditingBoard, categories } = useContext(BoardContext);
  const [name, updateName] = useInputState((currentBoard || { name: '' }).name);

  const onBoardAdd = async () => {
    const board: BoardInput = {
      name,
      groupingCriteria: categories
    }
    await editBoard(board);

    toggleEditingBoard();
  };

  return (
    <Fragment>
      <h1 className='title'>Edit board</h1>
      <h4>Please enter a board name</h4>
      <Form onSubmit={onBoardAdd}>
        <Form.Group controlId='name'>
          <Form.Label>Name</Form.Label>
          <Form.Control type='text' placeholder='Board name' value={name} name='name' onChange={updateName}/>
          <Form.Text className='text-muted'>
            Please enter a board name.
          </Form.Text>
        </Form.Group>

        <CategoryWidget />

        <div className={'footer'}>
          <Button
            variant='primary'
            size='lg'
            block={true}
            onClick={onBoardAdd}
            disabled={!name}
          >
            Save board
          </Button>

          <Button
            variant='outline-primary'
            size='lg'
            block={true}
            onClick={toggleEditingBoard}
          >
            Cancel
          </Button>
        </div>

      </Form>
    </Fragment>
  );
};

export default BoardEdit;
