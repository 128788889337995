import React, { useContext, useEffect } from 'react';
import { Alert, Tab, Tabs } from 'react-bootstrap';
import Spinner from '../../components/spinner/spinner.component';
import { SessionContext } from '../../contexts/SessionContext';
import CreateNote from '../../components/create-note/create-note.component';
import Error from '../../components/error/error';
import NameForm from './NameForm';
import Header from '../../components/header/header';
import { BoardContext } from '../../contexts/BoardContext';
import Debugger from '../../components/debug/debugger';
import NoteList from './NoteList';

interface SessionParticipateProps {
  id: string;
}

const SessionParticipate = ({ id }: SessionParticipateProps) => {
  const { currentSession, loading: loadingSession, error: errorSession, getSession, participant, setParticipant } = useContext(SessionContext);
  const { boardNotes } = useContext(BoardContext);

  // Load session if a direct link was used.
  useEffect(() => {
    if (!currentSession) {
      getSession({ id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNameChange = async (name) => {
    try {
      await setParticipant(name);
    } catch (e) {
      console.error(e.toString());
    }
  }

  if (loadingSession) {
    return <Spinner/>;
  }

  if (errorSession) {
    // todo: add remedy
    return <Error message={errorSession} />;
  }

  if (!currentSession) {
    // todo: add remedy
    return <Error message='No current session' />;
  }

  if (!currentSession.presentedBoard) {
    return (
      <Alert variant='light'>
        <p>Waiting for the host to present a board.</p>
      </Alert>
    );
  }

  return (
    <main className='page-content'>
      <Header/>
      <Debugger name='participant' value={participant} />

      {
        participant
        ? (
          <div>
            <CreateNote boardId={currentSession.presentedBoard}/>
            <h3>Notes</h3>
            {
              boardNotes ? (
                <Tabs defaultActiveKey='myNotes' id='uncontrolled-tab-example'>
                  <Tab eventKey='myNotes' title='Mine'>
                    <Debugger name='my board notes' value={boardNotes.filter(f => (f.participant || {}).id === participant.id)} />
                    <NoteList notes={boardNotes.filter(f => (f.participant || {}).id === participant.id)} />
                  </Tab>
                  <Tab eventKey='board' title='Board'>
                    <Debugger name='board notes' value={boardNotes} />
                    <NoteList notes={boardNotes} showName={true}/>
                  </Tab>
                </Tabs>
              ) : <p>No notes yet</p>
            }
          </div>
          )
        : <NameForm onChange={handleNameChange}/>
      }
    </main>
  )
};

export default SessionParticipate;
