import React, { useContext } from 'react';
import { Button, Form } from 'react-bootstrap';
import { BoardContext } from '../../contexts/BoardContext';
import useInputState from '../../hooks/useInputState';
import { BoardUpdate as BoardInput } from '../../typings';
import CategoryWidget from '../../components/category-widget/category-widget';

const BoardCreate = () => {
  const { addBoard, toggleCreatingBoard, categories } = useContext(BoardContext);
  const [name, updateName] = useInputState('');

  const onBoardAdd = async () => {
    const board: BoardInput = {
      name,
      groupingCriteria: categories
    }
    await addBoard(board);

    toggleCreatingBoard();
  };

  return (
    <>
      <h1 className='title'>Add board</h1>
      <h4>Please enter a board name</h4>
      <Form onSubmit={onBoardAdd}>
        <Form.Group controlId='name'>
          <Form.Label>Name</Form.Label>
          <Form.Control type='text' placeholder='Board name' value={name} name='name' onChange={updateName}/>
          <Form.Text className='text-muted'>
            Please enter a board name.
          </Form.Text>
        </Form.Group>

        <CategoryWidget />

        <div className={'footer'}>
          <Button
            variant='primary'
            size='lg'
            block={true}
            onClick={onBoardAdd}
            disabled={!name}
          >
            Create board
          </Button>

          <Button
            variant='outline-primary'
            size='lg'
            block={true}
            onClick={toggleCreatingBoard}
          >
            Cancel
          </Button>
        </div>
      </Form>
    </>
  );
};

export default BoardCreate;
