import Amplify, { Auth as ampAuth } from 'aws-amplify';
import awsConfig from '../aws-config';
import { InMemoryCache } from 'apollo-cache-inmemory';
// import { persistCache } from 'apollo-cache-persist';

import { ApolloLink } from 'apollo-link';
import ApolloClient from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';

import { resolvers, typeDefs } from '../graphql/resolvers';
import { createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';

import Log from '../libs/log';
const log = Log({ source: 'amplify:amplify' });

Amplify.configure(awsConfig);

export function Auth() {

  let anonymousSignInError = false;

  const GetApolloClient = () => {
    const cache = new InMemoryCache();

    const url = awsConfig.aws_appsync_graphqlEndpoint;
    const region = awsConfig.aws_appsync_region;
    const auth = {
      type: awsConfig.aws_appsync_authenticationType,
      credentials: () => ampAuth.currentCredentials(),
      jwtToken: async () =>
        (await ampAuth.currentSession()).getAccessToken().getJwtToken()
    };

    const httpLink = createHttpLink({ uri: url });

    const link = ApolloLink.from([
    // @ts-ignore
      createAuthLink({ url, region, auth }),
      createSubscriptionHandshakeLink(url, httpLink)
    ]);

    return new ApolloClient({
      link,
      cache,
      typeDefs,
      resolvers
    })
  };

  const SignIn = ({ username, password }: { username: string, password: string }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const user = await ampAuth.signIn(username, password);

        // Return user if everything is fine
        if (!user.challengeName) {
          return resolve({ user, signedIn: true });
        }

        // check for MFA
        if (['SOFTWARE_TOKEN_MFA', 'SMS_MFA'].includes(user.challengeName)) {
          return resolve({ user, hasMFA: true, signedIn: false });
        }

        // check for MFA
        if (['MFA_SETUP'].includes(user.challengeName)) {
          return reject('MFA need to be set up');
        }

        // check for NEW_PASSWORD_REQUIRED
        if (['NEW_PASSWORD_REQUIRED'].includes(user.challengeName)) {
          const { requiredAttributes } = user.challengeParam; // the array of required attributes, e.g ['email', 'phone_number']
          return resolve({ user, requiredAttributes, newPassword: true, signedIn: false });
        }

      } catch (err) {
        console.warn('amplify.utils#SignIn catch:', err);
        return reject(err.message);
      }
    });
  };

  const SignInAnonymous = () => {
    const username: string = 'anon@remotethinking.com';
    const password: string = '1TvuK@P7';

    return new Promise<void>((resolve, reject) => {

      if (anonymousSignInError) {
        return reject('Unable to sign in anonymously.');
      }

      SignIn({ username, password })
        .then(() => {
          return resolve();
        })
        .catch((err) => {
          console.error('Anonymous sign in failed', err);
          anonymousSignInError = true;
          return resolve();
        })

    })

  };

  const SignOut = () => {
    return new Promise(async (resolve, reject) => {
      try {
        await ampAuth.signOut();
        log.info({ action: 'Auth:SignOut:success', message: 'User Signed out' })
      } catch (err) {
        log.error({ action: 'Auth:SignOut:error', error: err });
        return reject(err.message);
      }
    });
  };

  const ConfirmSignIn = ({ user, code }: { user: any, code: string }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const loggedUser = await ampAuth.confirmSignIn(
          user,   // Return object from ampAuth.signIn()
          code,   // Confirmation code
          user.challengeName // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
        );

        return ({ user: loggedUser })
      } catch (err) {
        console.warn('amplify.utils#ConfirmSignIn catch:', err);
        return reject(err.message);
      }
    });


  };

  const ConfirmNewPassword = ({ user, newPassword }: { user: any, newPassword: string }) => {
    return new Promise(async (resolve, reject) => {

      try {
        const loggedUser = await ampAuth.completeNewPassword(
          user,
          newPassword,
          {
          }
        );

        return ({ user: loggedUser });
      } catch (err) {
        console.warn('amplify.utils#ConfirmNewPassword catch:', err);
        return reject(err.message);
      }
    });
  };

  const getAuthenticatedUser = () => {
    return new Promise((resolve, reject) => {
      ampAuth.currentAuthenticatedUser()
        .then(user => {
          log.info({ action: 'Auth:getAuthenticatedUser', data: { user } });
          return resolve(user);
        })
        .catch(err => {
          log.error({ action: 'Auth:getAuthenticatedUser:error', error: err });
          return reject(err);
        });
    })
  };

  return {
    ApolloClient: GetApolloClient,
    SignIn,
    SignInAnonymous,
    SignOut,
    ConfirmSignIn,
    ConfirmNewPassword,
    getAuthenticatedUser
  };
}
