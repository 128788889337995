import React from 'react';
import { accessorPropsType } from './utils';

const TextMiddle = ({ value }: { value: string }) => (
  <text
    className='Notes__text'
    y={-10}
    textAnchor='middle'
    dominantBaseline='middle'
  >
    {value}
  </text>
)

const TextEnd = ({ value, direction = 1 }: { value: string, direction?: number }) => (
  <text
    className='Notes__text'
    x={direction ? -5 : 5}
    dominantBaseline='middle'
    textAnchor={direction ? 'end' : 'start'}
  >
    {value}
  </text>
)

interface NodeProps {
  source: {
    data?: {
      name?: string;
    }
    y: number;
    x: number;
    depth: number;
    children?: any;
  };
  xAccessor: accessorPropsType;
  yAccessor: accessorPropsType;
  directionAccessor: accessorPropsType;
  onClick?: (source: any) => void;
}
const MindMapNode = ({ source, xAccessor, yAccessor, onClick = () => null, directionAccessor } : NodeProps) => {
  const nameAccessor = (val: any) => val && val.data && val.data.name;

  return (
    <g
      className={'MindMap__node'}
      transform={`translate(${yAccessor(source)}, ${xAccessor(source)})`}
      onClick={() => onClick(source)}
    >
      {
        source.children ?
          <TextMiddle value={nameAccessor(source)}/> :
          <TextEnd value={nameAccessor(source)} direction={directionAccessor(source)}/>
      }
    </g>
  );
}

export default MindMapNode
