import gql from 'graphql-tag';

export const SUBSCRIPTION_NOTES = gql`
  subscription subscribeToNotes($boardId: ID!) {
    onNoteUpsert(boardId: $boardId) {
      id
      boardId
      status
      created
      modified
      description
      group
      tags
      participant {
        id
        user {
          id
          name
        }
        name
      }
    }
  }`;

export const SUBSCRIPTION_SESSION_BOARDS = gql`
  subscription subscribeToBoards($sessionId: ID!) {
    onBoardUpsert(sessionId: $sessionId) {
      id
      status
      created
      modified
      name
      order
      template
      groupingCriteria{
        id
        name
        order
        color
      }
    }
  }
`

export const SUBSCRIPTION_SESSION_PARTICIPANTS = gql`
  subscription subscribeToParticipants($sessionId: ID!) {
    onParticipantUpsert(sessionId: $sessionId) {
      id
      status
      created
      modified
      name
      sessionId
    }
  }
`

export const SUBSCRIPTION_SESSION = gql`
  subscription subscribeToSession($id: ID!) {
    onSessionUpdate(id: $id) {
      id
      status
      created
      modified
      name
      presentedBoard
    }
  }
`
