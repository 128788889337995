import React from 'react';

interface AvatarProps {
  name: string;
}

const Avatar = ({ name }: AvatarProps) => {
  const avatarFromStr = (str) => {
    if (!str) {
      return '';
    }
    // If one word, use the first two chars.
    if (str.indexOf(' ') === -1) {
      return str.substr(0, 2);
    }

    // Multi words, use the fist chr of the first two words.
    const [word1, word2] = str.split(' ');
    return `${word1[0]}${word2[0]}`;
  };
  return (
    <div className='list-thumb shadow-sm avatar40 bg-primary text-primary-light rounded-circle'>
      {avatarFromStr(name)}
    </div>
  )
}

export default Avatar;
