import React from 'react';

import './spinner.styles.scss';

const spinner = ({ message, height = '60vh', width='100%' }: { message?: string, height?: string, width?: string }) => {
  const divStyle = {
    height,
    width
  };

  return (
    <div
      className={'spinner-overlay'}
      style={divStyle}
    >
      <div className={'spinner-content'} >

        <div className={'spinner-container'} />
        {
          message ? <h3>{ message }</h3> : null
        }
      </div>
    </div>
  );
};

export default spinner;
