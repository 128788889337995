import React from 'react';

interface PortletProps {
  children: React.ReactNode;
  className?: string;
  title?: string;
}

const Portlet = ({ children, className, title }: PortletProps) => {
  const header = (
    <div className='portlet-header flex-row flex d-flex align-items-center b-b'>
      <div className='flex d-flex flex-column'>
        <h3>{title}</h3>
      </div>
    </div>
  );

  return (
    <div className={className}>
      <div className='portlet-box ui-buttons-col mb-30'>
        {title && header}
        <div className='portlet-body'>
          {children}
        </div>
      </div>
    </div>
  )
}

export default Portlet;
