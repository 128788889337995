const awsManual = {
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: 'eu-west-1',

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: 'eu-west-1_rE6Yfv5K8',

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: '5rupkjds3j6r387h5p47o1tm3n',

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: false,

    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    authenticationFlowType: 'USER_PASSWORD_AUTH'
  },
  'aws_appsync_graphqlEndpoint': 'https://shpwh7inejbhvkslxyvcyzoosa.appsync-api.eu-west-1.amazonaws.com/graphql',
  'aws_appsync_region': 'eu-west-1',
  'aws_appsync_authenticationType': 'AMAZON_COGNITO_USER_POOLS', // You have configured Auth with Amazon Cognito User Pool ID and Web Client Id
};


export default awsManual;
