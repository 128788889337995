import React from 'react';
import { accessorPropsType } from './utils';

interface MindMapParentProps {
  source: {
    parent: {
      x: number;
      color: string;
      depth: number;
    };
    x: number;
    color: string;
    depth: number;
  };
  xAccessor: accessorPropsType;
  yAccessor: accessorPropsType;
  colorAccessor: accessorPropsType<string>;
  directionAccessor: accessorPropsType;
}

const MindMapLink = ({ source, xAccessor, yAccessor, colorAccessor, directionAccessor }: MindMapParentProps) => {

  function diagonal(s, d) {
    if (directionAccessor(s)) {
      return `M ${yAccessor(s)} ${xAccessor(s)}
            L ${yAccessor(s) - (0.2 * 180)} ${xAccessor(s)},
            C ${(yAccessor(s) + yAccessor(d)) / 2} ${xAccessor(s)},
              ${(yAccessor(s) + yAccessor(d)) / 2} ${xAccessor(d)},
              ${yAccessor(d) + (0.2 * 180)} ${xAccessor(d)},
            L ${yAccessor(d)} ${xAccessor(d)}`;
    }

    return `M ${yAccessor(s)} ${xAccessor(s)}
            L ${yAccessor(s) + (0.2 * 180)} ${xAccessor(s)},
            C ${(yAccessor(s) + yAccessor(d)) / 2} ${xAccessor(s)},
              ${(yAccessor(s) + yAccessor(d)) / 2} ${xAccessor(d)},
              ${yAccessor(d) - (0.2 * 180)} ${xAccessor(d)},
            L ${yAccessor(d)} ${xAccessor(d)}`;
  }

  return (
    <g
      className={'MindMap__link'}
      style={{ stroke: colorAccessor(source) }}
    >
      <path
        d={diagonal(source.parent, source)}
      />
    </g>
  );
}

export default MindMapLink
