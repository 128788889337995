import React, { useContext } from 'react';
import { DebugContext } from '../../contexts/DebugContext';
import useToggle from '../../hooks/useToggle';
import { Button } from 'react-bootstrap';

type Props = {
  name: string;
  value: any;
}

const Debugger = (props: Props) => {
  const { debugging } = useContext(DebugContext);
  const [show, toggleShow] = useToggle(false);

  return (
    <>
      {
        debugging
          ? (
            <div>
              <Button variant='outline-info' size='sm' onClick={toggleShow}>{show ? 'Hide' : 'Show'} {props.name}</Button>
              {show && (
                <pre>{typeof props.value === 'object'
                  ? JSON.stringify(props.value, null, 1)
                  : props.value.toString()}</pre>
              )}
            </div>
          )
          : null}
    </>
  );
};

export default Debugger;
