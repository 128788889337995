import React, { Fragment, useContext, useState } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';

import './create-note.styles.scss';
import { useMutation } from '@apollo/react-hooks';
import { M_CREATE_NOTE } from '../../graphql/mutations';
import Spinner from '../spinner/spinner.component';
import { Button, Form } from 'react-bootstrap';
import useInputState from '../../hooks/useInputState';
import { BoardContext } from '../../contexts/BoardContext';
import { SessionContext } from '../../contexts/SessionContext';

interface CreateNoteProps {
  boardId: string;
  callback?: Function;
}

const CreateNote = ({ boardId, callback }: CreateNoteProps) => {
  const { participant, loading, error: participantError } = useContext(SessionContext);
  const { presentingBoard } = useContext(BoardContext);
  const [createNote] = useMutation(M_CREATE_NOTE);
  // const { loading, error: participantError, data } = useQuery(Q_GET_PARTICIPANT);
  const [message, updateMessage] = useInputState('');
  const [categories, updateCategories] = useState<string[]>([]);

  let [submitting, setSubmitting] = useState(false);
  let [error, setError] = useState<any>(null);
  let [success, setSuccess] = useState(false);

  if (!boardId) {
    return (<p>Board not set</p>);
  }

  if (loading) {
    return (<Spinner/>);
  }

  const onNoteAdd = async () => {
    if (!participant) {
      throw new Error('Participant not set');
    }
    setSubmitting(true);
    const input: any = {
      note: {
        boardId,
        description: message,
        participantId: participant.id
      }
    };

    if (categories && categories.length > 0 && presentingBoard) {
      const id = ((presentingBoard
        .groupingCriteria || [])
        .find(f => f.name === categories[0]) || {}).id;

      if (id) {
        input.note.group = id;
      }
    }

    console.log('createNote', input);

    createNote({ variables: input })
      .then(() => {
        // updateMessage('');
        // updateCategories([]);
        setSuccess(true);
        setSubmitting(false);

        if (callback) {
          callback(null, true);
        }
      })
      .catch((err) => {
        setError('Something went wrong' );
        console.log('create note error', err);
        setSubmitting(false);

        if (callback) {
          callback(err);
        }
      });
  };

  return (
    <Fragment>
      <h1 className='title'>Add a note</h1>
      {/*<h4>Please enter a board name</h4>*/}
      <Form onSubmit={onNoteAdd}>
        <Form.Group controlId='message'>
          <Form.Label>Message</Form.Label>
          <Form.Control type='text' placeholder='Message' value={message} name='message' onChange={updateMessage}/>
          <Form.Text className='text-muted'>
            Please enter a message.
          </Form.Text>
        </Form.Group>

        {
          presentingBoard && presentingBoard.groupingCriteria && presentingBoard.groupingCriteria.length > 0 ? (
              <Form.Group className='typeahead'>
                <Form.Label htmlFor='groups'>Category</Form.Label>
                <Typeahead
                  id='groups'
                  clearButton={true}
                  defaultSelected={categories}
                  labelKey='category'
                  multiple={false}
                  allowNew={false}
                  shouldSelect={true}
                  options={presentingBoard.groupingCriteria.map(m => m.name)}
                  placeholder='Set a note category'
                  onChange={(value) => updateCategories(value)}
                />
                <Form.Text className='text-muted'>
                  Category for the board
                </Form.Text>
              </Form.Group>
            ) : null
        }

        {error || participantError ? <p>error: {error || participantError}</p> : null}
        {success ? <p>Note sent</p> : null}
        <Button
          variant='primary'
          size='lg'
          block={true}
          onClick={onNoteAdd}
          disabled={!message || submitting}
        >
          Create note
        </Button>

      </Form>
    </Fragment>
  );
};

export default CreateNote;
