import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { SessionContext } from '../../contexts/SessionContext';
import Portlet from '../../components/Portlet/Portlet';
import { Col, Row } from 'react-bootstrap';

interface SessionPathSelectProps {
  id: string
}

const SessionPathSelect = ({ id }: SessionPathSelectProps) => {
  const { currentSession, loading, error, getSession } = useContext(SessionContext);

  // Load session if a direct link was used.
  useEffect(() => {
    if (!currentSession) {
      getSession({ id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <p>loading session</p>
  }

  if (error) {
    return <p>Session error: {error}</p>
  }

  return (
    <Row className='h-100 row align-items-center'>
      <Col md={{ span: 8, offset: 2 }}>
        <Portlet>
          <h2 className='text-center'>Choose your path..</h2>
          <p className='text-center pb-10'>
            You are about to join session: {currentSession && (currentSession.name || currentSession.ref)}
          </p>
          <Row className='justify-content-center'>
            <Col>
              <Link to={`/session/${id}/present`} className='btn btn-lg btn-block btn-outline-primary mb-10'>
                Only presenting
              </Link>
              <p>This is a cool way to share the session to your group in the boardroom. Now everyone can
                follow.</p>
            </Col>
            <Col>
              <Link to={`/session/${id}/participate`} className='btn btn-lg btn-block btn-primary mb-10'>
                I am going to participate
              </Link>
              <p>If you would like to actively collaborate and vote using your device, this is for you.</p>
            </Col>
          </Row>
        </Portlet>
      </Col>
    </Row>
  );
};

export default SessionPathSelect;
