import React, { useContext, useEffect } from 'react';
import './SessionPresent.scss';
import Board from '../../components/board/board';
import Footer from '../../components/footer/Footer';
import Header from '../../components/header/header';
import ShareSessionDetails from '../../components/ShareSessionDetails/ShareSessionDetails';
import Sidebar from '../..//components/sidebar/Sidebar';
import { BoardContext } from '../../contexts/BoardContext';
import { SessionContext } from '../../contexts/SessionContext';

interface SessionPresentProps {
  id: string;
}

const SessionPresent = ({ id }: SessionPresentProps) => {
  const { currentSession, loading, error, getSession } = useContext(SessionContext);
  const { presentingBoard } = useContext(BoardContext);

  // Load session if a direct link was used.
  useEffect(() => {
    console.log('render', 'sessionPresent');
    if (id) {
      getSession({ id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (loading) {
    return <p>loading session</p>
  }

  if (error) {
    return <p>Session error: {error}</p>
  }

  const sessionDetails = currentSession && !(currentSession.boards || currentSession.presentedBoard) &&
    <ShareSessionDetails session={currentSession}/>;

  const presentedBoard = presentingBoard ?
    <Board initialBoard={presentingBoard}/> : (
      <div>
        <p>Waiting for the host to present a board</p>
      </div>
    )

  return (
    <div className='page-wrapper'>
      <Sidebar version={'1.0.0-alpha1'}/>
      <main className='content bg-pattern'>
        <Header/>
        <div className='page-content'>
          <div className='container-fluid'>
            <div className='spectate-page'>
              {error ? <p>error: {error}</p> : null}

              {sessionDetails}

              {presentedBoard}
            </div>
          </div>
        </div>

        <Footer/>
      </main>
    </div>
  )
};

export default SessionPresent;
