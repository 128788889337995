import React, { useContext } from 'react';
import './Sections.scss';
import SignIn from '../../components/sign-in/sign-in.component';
import StartViewer from '../../components/session-join/session-join';
import Spinner from '../../components/spinner/spinner.component';
import SectionHost from './SectionHost';
import { AuthContext } from '../../contexts/AuthContext';

const Sections = () => {
  const { isAuthenticated, authenticating: loading } = useContext(AuthContext);

  if (loading) {
    return (<Spinner />);
  }

  return (
    <div className='Home'>
      <div className='Home-aside1'>
        <StartViewer/>
      </div>
      <div className='Home-aside2 pt-60'>
        <h1>Host a session</h1>
        {
          !isAuthenticated ? (
            <div>
              <h4>Please enter your login credentials to proceed.</h4>
              <SignIn/>
            </div>
          ) : (
            <div>
              <h4>Welcome back.</h4>
              <SectionHost />
            </div>
          )
        }
      </div>
    </div>
  );
};

export default Sections;
