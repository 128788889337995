import React from 'react';
import { Alert } from 'react-bootstrap';

type Props = {
  name?: string;
  message: string;
}

const error = (props: Props) => {

  return (
    <>
      <Alert variant='danger'>
        {props.name && <h3>{props.name}</h3>}
        <p>{props.message}</p>
      </Alert>
    </>
  );
};

export default error;
