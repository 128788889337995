import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import { ApolloProvider } from 'react-apollo';
import { Auth } from './amplify/amplify.utils';
import App from './App';
import './index.scss';
import Log from './libs/log';

// Apollo setup
const auth = Auth();
const client = auth.ApolloClient();

client.writeData({
  data: {
    currentUser: {
      sub: null,
      username: null,
      __typename: 'User'
    },
    participant: {
      name: '',
      key: '',
      __typename: 'Participant'
    }
  }
});

// Logger setup
if (process.env.NODE_ENV !== 'production') {
  localStorage.setItem('debug', 'rt:*verbose,rt:*debug,rt:*info,rt:*warning,rt:*error');
}
const log = Log({ source: 'root' });

class DebugRouter extends HashRouter {
  constructor(props) {
    super(props);
    log.verbose({ action: 'HashRouter:init', data: { history: (this as any).history } });

    (this as any).history.listen((location, action) => {
      log.verbose({
        action: 'HashRouter:route',
        data: {
          location,
          action,
          history: (this as any).history
        }
      });
    });
  }
}

ReactDOM.render(
  (
    <ApolloProvider client={client}>
      <DebugRouter>
        <App/>
      </DebugRouter>
    </ApolloProvider>
  ),
  document.getElementById('root')
);
