import React, {Component} from 'react';

class Footer extends Component<any, any> {
  render() {
    return (
      <footer className='content-footer bg-white b-t'>
        <div className='d-flex flex align-items-center pl-15 pr-15'>
          <div className='d-flex flex p-3 ml-auto'>
            <div>
              {/*<a href='#' className='d-inline-flex pl-0 pr-2 b-r'>Contact</a>
              <a href='#' className='d-inline-flex pl-2 pr-2 b-r'>Storage</a>
              <a href='#' className='d-inline-flex pl-2 pr-2 '>Privacy</a>*/}
            </div>
          </div>
          <div className='d-flex flex p-3 mr-auto justify-content-end'>
            <div className='text-muted'>© Copyright 2020.</div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
