import React, { useState } from 'react';
import FormInput from '../form-input/form-input.component';
import CustomButton from '../custom-button/custom-button.component';

import './noteForm.scss';
import { useMutation } from '@apollo/react-hooks';
import { M_UPDATE_NOTE } from '../../graphql/mutations';

interface NoteParams {
  note: any,
  onDone: () => void
}

const NoteForm = ({ note, onDone }: NoteParams) => {
  const [notesUpdate] = useMutation(M_UPDATE_NOTE);
  let [message, setMessage] = useState(note.description);
  let [submitting, setSubmitting] = useState(false);
  let [error, setError] = useState<any>(null);
  let [success, setSuccess] = useState(false);

  const handleNoteMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setMessage(value);
  };

  const handleSubmit = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setSubmitting(true);
    notesUpdate({
      variables: {
        note: {
          description: message,
          status: 'active',
          id: note.id,
          boardId: note.boardId
        }
      }
    })
      .then(() => {
        setMessage('');
        setSuccess(true);
        setSubmitting(false);
        onDone();
      })
      .catch((err) => {
        setError('Something went wrong');
        console.log('update note error', err);
        setSubmitting(false);
      });
  };

  const handleRemove = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setSubmitting(true);
    notesUpdate({
      variables: {
        note: {
          status: 'removed',
          id: note.id,
          boardId: note.boardId
        }
      }
    })
      .then(() => {
        setMessage('');
        setSuccess(true);
        setSubmitting(false);
        onDone();
      })
      .catch((err) => {
        setError('Something went wrong');
        console.log('remove note error', err);
        setSubmitting(false);
      });
  };

  return (
    <div className='edit-note'>

      {error ? <p>error: {error}</p> : null}

      <FormInput
        type='text'
        name='noteMessage'
        value={message}
        handleChange={handleNoteMessageChange}
        label='Message'
      />

      {success ? <p>Note sent</p> : null}

      <div className='Note-modal-btns'>
        <CustomButton colour='outline-primary' onClick={() => onDone()} disabled={submitting}>Cancel</CustomButton>
        <CustomButton colour='primary' onClick={(e) => handleRemove(e)}>Remove note</CustomButton>
        <CustomButton colour='primary' onClick={(e) => handleSubmit(e)}>Update note</CustomButton>
      </div>
    </div>
  )

};

export default NoteForm;
