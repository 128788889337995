import Sidebar from '../../components/sidebar/Sidebar';
import Board from '../../components/board/board'
import Header from '../../components/header/header';
import CustomButton from '../../components/custom-button/custom-button.component';
import Footer from '../../components/footer/Footer';
import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { SessionContext } from '../../contexts/SessionContext';
import Spinner from 'react-bootstrap/Spinner';
import { BoardContext } from '../../contexts/BoardContext';
import BoardEdit from './BoardEdit';
import './SessionHost.scss';
import BoardCreate from './BoardCreate';
import { Button, Overlay, Card } from 'react-bootstrap';
import CreateNote from '../../components/create-note/create-note.component';

interface HostProps {
  id: string;
}

const SessionHost = ({ id }: HostProps) => {
  const { sessionBoards, getSession, loading } = useContext(SessionContext);
  const {
    currentBoard,
    presentingBoard,
    presentCurrentBoard,
    creatingBoard,
    toggleCreatingBoard,
    editingBoard,
    toggleEditingBoard
  } = useContext(BoardContext);
  const [showCreateNote, setShowCreateNote] = useState(false);
  const targetCreateNote = useRef(null);


  useEffect(() => {
    if (id) {
      console.log(`getting session... ${id}`)
      getSession({ id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return (
    <div className='page-wrapper'>
      <Sidebar version={'1.0.1'}/>
      <main className='content bg-pattern'>
        <Header/>
        <div className='page-content'>
          <div className='container-fluid'>
            <div className='host-page'>
              <div className='session-settings'>
                <Link to={'/'} className='btn btn-outline-primary'>
                  End session
                </Link>
                {currentBoard ? (
                  <CustomButton
                    colour='outline-primary'
                    onClick={toggleEditingBoard}
                  >
                    Edit
                  </CustomButton>
                ) : null}
                <CustomButton
                  colour='outline-primary'
                  onClick={toggleCreatingBoard}
                >
                  New Board
                </CustomButton>
                <CustomButton
                  colour='outline-primary'
                  onClick={() => {
                  }}
                >
                  Export
                </CustomButton>
                {
                  currentBoard ? (
                      <Fragment>
                        <CustomButton
                          colour='outline-primary'
                          onClick={() => presentCurrentBoard()}
                          disabled={(presentingBoard || { id: 'a' }).id === currentBoard.id}
                        >
                          {(presentingBoard || { id: 'a' }).id !== currentBoard.id ? 'Present board' : 'Presenting'}
                        </CustomButton>

                        <Button ref={targetCreateNote} onClick={() => setShowCreateNote(!showCreateNote)}>
                          Add a note
                        </Button>
                        <Overlay target={targetCreateNote.current} show={showCreateNote} placement='bottom'>
                          <Card>
                            <Card.Body>
                              <CreateNote boardId={currentBoard && currentBoard.id} callback={() => { setShowCreateNote(!showCreateNote); }}/>
                            </Card.Body>
                          </Card>
                        </Overlay>
                      </Fragment>
                    )
                    : null
                }
              </div>

              {
                loading ?
                  <Spinner animation='border' variant='primary'/> : (
                    <div>
                      {sessionBoards && sessionBoards.length > 0 && <Board/>}
                    </div>
                  )
              }
            </div>
          </div>
        </div>

        {
          editingBoard ? <div className={'edit-board'}><BoardEdit/></div> : null
        }

        {
          creatingBoard ? <div className={'edit-board'}><BoardCreate/></div> : null
        }

        <Footer/>
      </main>
    </div>
  )
}

export default SessionHost;
