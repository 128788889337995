import React, { useContext, useEffect, useState } from 'react';
import * as d3 from 'd3';

import Chart from './Chart/Chart';
import { accessorPropsType, useChartDimensions } from './Chart/utils';
import MindMapNode from './Chart/MindMapNode';
import MindMapLink from './Chart/MindMapLink';
import { DebugContext } from '../../contexts/DebugContext';

interface MindMapProps {
  name?: string;
  data?: any;
  xAccessor?: accessorPropsType;
  yAccessor?: accessorPropsType;
  keyAccessor: accessorPropsType<string>;
  onNodeSelect?: (node: any) => void;
}

const MindMap = ({
  name = '',
  data,
  xAccessor = d => d.x,
  yAccessor = d => d.depth * 300,
  keyAccessor = (d, i) => i.toString(),
  onNodeSelect = () => null
}: MindMapProps) => {
  const { debugging } = useContext(DebugContext);
  const [nodesLeft, setNodesLeft] = useState<any>();
  const [nodesRight, setNodesRight] = useState<any>();
  const [linksLeft, setLinksLeft] = useState<any>();
  const [linksRight, setLinksRight] = useState<any>();
  const [rootLeft, setRootLeft] = useState<any>();
  const [rootRight, setRootRight] = useState<any>();
  const [treeData, setTreeData] = useState<any>({
    'name': '',
    'children': []
  });

  useEffect(() => {
    if (data) {

      console.log('data', data);

      setTreeData({
        'name': name,
        'children': data.reduce((memo, cur) => {
          // console.log('cur', cur);
          const group = cur.group || { id: 'zz', name: 'Not categorised', order: 0, color: '#123123'};

          if (!memo.find(f => f.id === group.id)) {
            memo.push({
              ...group,
              'children': []
            })
          }

          const item = memo.find(f => f.id === group.id);

          item.children.push({
            name: cur.description,
            key: keyAccessor(cur)
          })

          return memo;
        }, debugging ? [{
            'name': 'Level 2: B',
            'children': [
              { 'name': 'Son of B' },
              { 'name': 'Daughter of B' }
            ]
          }] : []
        )
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    if (rootLeft) {
      const treeData = treemap(rootLeft);

      const descendants = treeData.descendants()
        .map((m) => ({
          ...m,
          x: xAccessor(m),
          y: yAccessor(m)
        }))

      setNodesLeft(descendants);
      setLinksLeft(descendants.slice(1));

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rootLeft])

  useEffect(() => {
    if (rootRight) {
      const treeData = treemap(rootRight);

      const descendants = treeData.descendants()
        .map((m) => ({
          ...m,
          x: xAccessor(m),
          y: yAccessor(m)
        }))

      setNodesRight(descendants);
      setLinksRight(descendants.slice(1));

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rootRight])

  useEffect(() => {
    const treeDataLeft = {
      ...treeData,
      children: treeData
        .children
        .reduce((memo, cur, index) => {
          if (index % 2) {
            return [ ...memo, cur ]
          } else {
            return memo;
          }
        }, [])
    };
    const temp_root_left = d3.hierarchy(treeDataLeft, function(d) { return d.children; });
    temp_root_left.x0 = dimensions.height / 2;
    temp_root_left.y0 = dimensions.width / 2;

    setRootLeft(temp_root_left);

    const treeDataRight = {
      ...treeData,
      children: treeData
        .children
        .reduce((memo, cur, index) => {
          if (!(index % 2)) {
            return [ ...memo, cur ]
          } else {
            return memo;
          }
        }, [])
    };
    const temp_root_right = d3.hierarchy(treeDataRight, function(d) { return d.children; });
    temp_root_right.x0 = dimensions.height / 2;
    temp_root_right.y0 = dimensions.width / 2;

    setRootRight(temp_root_right);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treeData])

  // TODO: remove width and height and calculate with useChartDimensions
  const [ref, dimensions] = useChartDimensions({
    width: 600,
    height: 600,
    marginBottom: 20
  })

  const treemap = d3.tree().size([1000, 1000]);

  // Toggle children on click.
  function click(d) {
    // console.log('click(d)', d);
    onNodeSelect({ key: d.data.key });
  }

  const xScale = d3.scaleLinear()
    .domain([0,1000])
    .range([0, dimensions.boundedWidth])
    .nice();

  const yScale = d3.scaleLinear()
    .domain([-500, 500])
    .range([0, dimensions.boundedHeight])
    .nice();

  const xAccessorScaled = d => xScale(xAccessor(d));
  const yAccessorScaled = d => yScale(yAccessor(d));
  const yAccessorScaledLeft = d => yScale(yAccessor(d) * -1);
  const colorAccessor = d => d.data.color || d.parent.data.color;

  return (
    <div className='ScatterPlot' ref={ref}>
      <Chart dimensions={dimensions}>
        <g>
          {linksRight ? linksRight.map((m, i) => (
            <MindMapLink
              key={i}
              source={m}
              xAccessor={xAccessorScaled}
              yAccessor={yAccessorScaled}
              colorAccessor={colorAccessor}
              directionAccessor={() => 0}
            />
          )) : null}
          {linksLeft ? linksLeft.map((m, i) => (
            <MindMapLink
              key={i}
              source={m}
              xAccessor={xAccessorScaled}
              yAccessor={yAccessorScaledLeft}
              colorAccessor={colorAccessor}
              directionAccessor={() => 1}
            />
          )) : null}
          {nodesRight ? nodesRight.map((m, i) => (
            <MindMapNode
              key={i}
              source={m}
              xAccessor={xAccessorScaled}
              yAccessor={yAccessorScaled}
              onClick={click}
              directionAccessor={() => 0}
            />
          )) : null}
          {nodesLeft ? nodesLeft.map((m, i) => (
            <MindMapNode
              key={i}
              source={m}
              xAccessor={xAccessorScaled}
              yAccessor={yAccessorScaledLeft}
              onClick={click}
              directionAccessor={() => 1}
            />
          )) : null}
        </g>
      </Chart>
    </div>
  )
}

export default MindMap
