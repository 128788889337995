import React, { useContext } from 'react';
import { SessionContext } from '../../contexts/SessionContext';
import { BoardContext } from '../../contexts/BoardContext';
import { Link } from 'react-router-dom';
import useToggle from '../../hooks/useToggle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faPlay, faProjectDiagram } from '@fortawesome/free-solid-svg-icons';
import { SidebarContext } from '../../contexts/SidebarContext';

interface SidebarProps {
  folded?: boolean;
  version: string;
}

const Sidebar = ({ version }: SidebarProps) => {
  const { sessionBoards, currentSession, presentingBoardId } = useContext(SessionContext);
  const { currentBoard, setBoard, creatingBoard, editingBoard, toggleCreatingBoard } = useContext(BoardContext);
  const { sidebarCollapsed, toggleSidebar } = useContext(SidebarContext);
  const [showSection, toggleShowSection] = useToggle(true);

  let foldedStyles = 'page-aside aside-fixed';
  if (sidebarCollapsed) {
    foldedStyles += ' aside-folded show';
  }

  return (
    <div className={foldedStyles}>
      <div className='sidenav darkNav'>
        {/* Hamburger */}
        <ul className='metisMenu pt-1 pb-2 d-md-none'>
          <li>
            <i className='navbar-dark nav-thumbnail text-white cursor-pointer sidenav-btn toggler-folded'>
              <span onClick={toggleSidebar}>
                <span className='navbar-toggler-icon'/>
              </span>
            </i>
          </li>
        </ul>

        {/* Logo */}
        <Link
          to='/'
          className='app-logo d-flex flex flex-row align-items-center overflow-hidden justify-content-center'
        >
          {sidebarCollapsed && (
            <span className='inline-icon d-inline-flex'>
              <i className='icon-Gaugage nav-thumbnail'>RT</i>
            </span>
          )}
          <span className='logo-text d-inline-flex'>
            <span className='font700 d-inline-block mr-1'>Remote</span> Thinking
          </span>
        </Link>

        {/* Boards */}
        <div className='flex'>
          <div className='slimScrollDiv App-slimScrollDiv'>
            <div className='aside-content slim-scroll App-slim-scroll'>
              <ul className='metisMenu pt-0' id='metisMenu'>
                <li className={showSection ? 'active' : ''}>
                  <i className='icon-Gaugage nav-thumbnail' onClick={toggleShowSection}>
                    <FontAwesomeIcon icon={faProjectDiagram}/>
                  </i>
                  {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                  <a className='link' onClick={toggleShowSection}>
                    <div className='d-flex justify-content-between align-items-center'>
                      <span className='nav-text'>Boards</span>
                      <span className={showSection ? 'animate-rotate-90-fwd mr-1' : 'animate-rotate-90-bck mr-1'}>
                        <FontAwesomeIcon icon={faCaretRight}/>
                      </span>
                    </div>
                  </a>
                  <ul className={showSection ? 'animate-swing-in-top-fwd' : 'animate-swing-out-top-bck'}>
                    {sessionBoards && sessionBoards.map((board) => {
                      return (
                        <li
                          key={board.id}
                          className={(!currentBoard && presentingBoardId && presentingBoardId === board.id) || (currentBoard && currentBoard.id && currentBoard.id === board.id) ? 'active' : ''}
                          onClick={() => (setBoard({ id: board.id }))}
                        >
                          <span className='nav-thumbnail cursor-pointer'>
                            {board.name.substr(0, 2)}
                            {currentSession && currentSession.presentedBoard === board.id && (
                              <span className='icon-present badge anibadge badge-primary mr-2 mb-2'>
                                <FontAwesomeIcon icon={faPlay}/>
                              </span>
                            )}
                          </span>
                          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                          <a>
                            {board.name}
                          </a>
                        </li>
                      );
                    })}
                    { !editingBoard && (
                      <li
                        className={''}
                        onClick={toggleCreatingBoard}
                      >
                        <span className='nav-thumbnail cursor-pointer'>
                          {creatingBoard ? 'x' : '+'}
                        </span>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a>
                          {creatingBoard ? 'cancel' : 'Add board'}
                        </a>
                      </li>
                    )}
                  </ul>
                </li>
              </ul>
            </div>
            <div className='slimScrollBar App-slimScrollBar'/>
            <div className='slimScrollRail App-slimScrollRail'/>
          </div>
        </div>

        {/* Footer */}
        <div className='aside-footer p-3 pl-25'>
          <div>
            v{version}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
