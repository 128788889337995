import React from 'react';
import QRCode from 'qrcode.react'

interface Props {
  link: string;
}

const QRCodeComponent = ({ link }: Props) => (
  <QRCode value={link} />
);


export default QRCodeComponent;
