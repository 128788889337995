import { useState, useRef } from 'react';

export type accessorPropsType<T = number> = (d: any, i?: any) => T;

/*export const callAccessor = (accessor, d, i) => (
  typeof accessor === 'function' ? accessor(d, i) : accessor
)*/

export interface dimensionsPropsType {
  height?: number;
  width?: number;
  marginTop?: number;
  marginRight?: number;
  marginBottom?: number;
  marginLeft?: number;
}

export const combineChartDimensions = dimensions => {
  let parsedDimensions = {
    marginTop: 40,
    marginRight: 30,
    marginBottom: 40,
    marginLeft: 75,
    ...dimensions,
  }

  return {
    ...parsedDimensions,
    boundedHeight: Math.max(parsedDimensions.height - parsedDimensions.marginTop - parsedDimensions.marginBottom, 0),
    boundedWidth: Math.max(parsedDimensions.width - parsedDimensions.marginLeft - parsedDimensions.marginRight, 0),
  }
}

export const useChartDimensions = passedSettings => {
  const ref = useRef();
  const dimensions = combineChartDimensions(passedSettings);

  const [width/*, changeWidth*/] = useState(1000);
  const [height/*, changeHeight*/] = useState(700);

  /*useEffect(() => {
    if (dimensions.width && dimensions.height) {
      return;
    }

    const element = ref.current;

    if (!element) {
      return;
    }

    const resizeObserver = new ResizeObserver(entries => {
      if (!Array.isArray(entries)) return
      if (!entries.length) return

      const entry = entries[0];

      console.log({
        width: entry.contentRect.width,
        height: entry.contentRect.height
      })

      /!*if (width !== entry.contentRect.width) changeWidth(entry.contentRect.width);
      if (height !== entry.contentRect.height) changeHeight(entry.contentRect.height);*!/
    })

    resizeObserver.observe(element);

    return () => resizeObserver.unobserve(element || JSON.parse('{}'));
  }, []);*/
  // }, [passedSettings, height, width, dimensions])

  const newSettings = combineChartDimensions({
    ...dimensions,
    width: dimensions.width || width,
    height: dimensions.height || height,
  })

  return [ref, newSettings]
}

let lastId = 0
export const useUniqueId = (prefix='') => {
  lastId++
  return [prefix, lastId].join('-')
}
