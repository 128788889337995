import React, { createContext, useEffect, useState } from 'react';
import { Board, Session, Status, Participant } from '../typings';
import { useLazyQuery, useMutation } from 'react-apollo';
import { Q_SESSION_BY_REF, Q_SESSION, Q_SESSION_BOARDS, Q_SESSION_PARTICIPANTS } from '../graphql/queries';
import { M_SESSION_CREATE, M_SESSION_UPDATE, M_SET_PARTICIPANT } from '../graphql/mutations';
import { SUBSCRIPTION_SESSION, SUBSCRIPTION_SESSION_BOARDS, SUBSCRIPTION_SESSION_PARTICIPANTS } from '../graphql/subscriptions';
import Log from '../libs/log';

const log = Log({ source: 'contexts:session' });

type SessionContextType = {
  loading: boolean,
  error: string,
  currentSession: Session | undefined,
  currentSessionId: string | undefined,
  presentingBoardId: string | undefined,
  createSession: ({ name }: { name?: string }) => Promise<Session>,
  updateSession: (updateParams: { name?: string, status?: Status, presentedBoard?: string }) => Promise<void>,
  getSession: (id?, ref?) => void,
  sessionBoards: Board[],
  participant: Participant | undefined,
  setParticipant: (name: string) => Promise<Participant>
  participants: Participant[]
};
type SessionContextProps = {
  children: React.ReactNode;
};

export const SessionContext = createContext({} as SessionContextType);

export function SessionProvider({ children }: SessionContextProps): any {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const [currentSession, setCurrentSession] = useState<Session>();
  const [currentSessionId, setCurrentSessionId] = useState<string>();
  const [presentingBoardId, setPresentingBoardId] = useState<string>();

  const [sessionCreate] = useMutation(M_SESSION_CREATE);
  const [sessionUpdate] = useMutation(M_SESSION_UPDATE);

  const [getById, { loading: loadingById, data: dataById, subscribeToMore: subscribeToChangesById }] = useLazyQuery(Q_SESSION);
  const [getByRef, { loading: loadingByRef, data: dataByRef, error: errorByRef, subscribeToMore: subscribeToChangesByRef }] = useLazyQuery(Q_SESSION_BY_REF);
  const [loadParticipants, { loading: loadingParticipants, data: participants, subscribeToMore: subscribeToParticipants }] = useLazyQuery(Q_SESSION_PARTICIPANTS);

  const [getSessionBoards, { subscribeToMore: subscribeToBoards, ...boards }] = useLazyQuery(Q_SESSION_BOARDS);

  /* Session participant */
  const [currentParticipant, setCurrentParticipant] = useState<Participant>();
  const [setParticipantSession] = useMutation(M_SET_PARTICIPANT);

  log.debug({ action: 'sessionContext:boards', data: boards });


  useEffect(() => {
    // load participant
    const p: Participant = JSON.parse(localStorage.getItem('participant') || 'null');
    console.log('p', p);

    if (p && p.sessionId === currentSession?.id) {
      setCurrentParticipant(p);
    } else {
      setCurrentParticipant(undefined);
    }

    setLoading(false);
  }, [currentSession])

  useEffect(() => {
    log.debug({ action: 'sessionContext:useEffect:dataById', data: { dataById } });
    if (dataById && dataById.session) {
      log.verbose({ action: 'sessionContext:setCurrentSession:byId', data: dataById.session });

      setCurrentSession(dataById.session);
      setCurrentSessionId(dataById.session.id);
      setPresentingBoardId(dataById.session.presentedBoard);
    }
  }, [dataById]);

  useEffect(() => {
    log.debug({ action: 'sessionContext:useEffect:loadingById', data: { loadingById } });
    if (!loadingById && dataById && dataById.session) {
      log.verbose({ action: 'SessionContext:subscribeToChangesById', data: { id: dataById.session.id } });

      if (subscribeToChangesById && dataById.session.id) {
        subscribeToChangesById({
          document: SUBSCRIPTION_SESSION,
          variables: { id: dataById.session.id },
          updateQuery: (prev, { subscriptionData }) => {
            log.verbose({
              action: 'SessionContext:subscribeToChangesById:updateQuery',
              data: {
                prev,
                subscriptionData
              }
            });
          }
        });
      }

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingById]);

  useEffect(() => {
    log.debug({ action: 'sessionContext:useEffect:dataByRef', data: { dataByRef } });
    if (dataByRef && dataByRef.sessionByRef) {
      log.verbose({ action: 'sessionContext:setCurrentSession:byRef', data: dataByRef.sessionByRef });

      setCurrentSession(dataByRef.sessionByRef);
      setCurrentSessionId(dataByRef.sessionByRef.id);
      setPresentingBoardId(dataByRef.sessionByRef.presentedBoard);

      if (subscribeToChangesByRef && dataByRef.sessionByRef.id) {
        log.verbose({ action: 'SessionContext:useEffect:currentSession:subscribeToChangesByRef:subscribing', data: { currentSession } });
        subscribeToChangesByRef({
          document: SUBSCRIPTION_SESSION,
          variables: { id: dataByRef.sessionByRef.id }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataByRef]);

  useEffect(() => {
    log.debug({ action: 'sessionContext:useEffect:participants' });
    if (participants && participants.participants) {


      if (subscribeToParticipants && currentSessionId) {
        log.verbose({ action: 'sessionContext:useEffect:participants:subscribe', data: { currentSessionId } });
        subscribeToParticipants({
          document: SUBSCRIPTION_SESSION_PARTICIPANTS,
          variables: { sessionId: currentSessionId },
          updateQuery: (prev, {subscriptionData, variables}) => {
            log.verbose({
              action: 'sessionContext:useEffect:participants:subscribe:change',
              data: {subscriptionData, variables}
            });
            log.verbose({action: 'sessionContext:useEffect:participants:subscribe:prev', data: prev });

            if (!subscriptionData.data) return prev;
            const { onParticipantUpsert: {
              id,
              status,
              modified,
              ...participantParams
            } } = subscriptionData.data;

            const prevItems = (prev.participants || []);
            const updateIndex = prevItems.findIndex((f) => f.id === id);

            let update: any;
            if (updateIndex >= 0) {
              prev.participants[updateIndex] = {
                ...prev.participants[updateIndex],
                status,
                modified,
                __typename: 'participant'
              };
              update = [ ...prev.participants ];
            } else {
              update = [ ...prevItems, {
                ...participantParams,
                id,
                status,
                modified,
                __typename: 'participant'
              }]
            }

            log.verbose({action: 'sessionContext:useEffect:participants:subscribe:latest', data: update });

            return {
              participants: update
            };
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [participants]);

  // Session boards
  useEffect(() => {
    log.debug({ action: 'sessionContext:useEffect:currentSessionId', data: { currentSessionId } });
    if (!currentSessionId) {
      log.debug({ action: 'boardContext:useEffect:sessionBoards:no-current-session' });
      return;
    }

    log.debug({ action: 'boardContext:useEffect:sessionBoards:getBoards', data: { variables: { sessionId: currentSessionId } } });

    // load the sessions boards
    getSessionBoards({
      variables: { sessionId: currentSessionId }
    })

    // load the session participants
    loadParticipants({
      variables: { sessionId: currentSessionId }
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSessionId])

  // Session boards subscription
  useEffect(() => {
    log.debug({ action: 'sessionContext:useEffect:boardsSubscription', data: { boardLoading: boards.loading } });
    if (!boards.loading && currentSessionId && subscribeToBoards) {
      log.verbose({ action: 'sessionContext:useEffect:boardsSubscription:subscribing', data: { currentSessionId } });
      subscribeToBoards({
        document: SUBSCRIPTION_SESSION_BOARDS,
        variables: { sessionId: currentSessionId },
        updateQuery: (prev, { subscriptionData }) => {
          log.verbose({ action: 'boardContext:useEffect:boardsSubscription:subscribeToMore:change', data: subscriptionData.data });
          log.verbose({ action: 'boardContext:useEffect:boardsSubscription:subscribeToMore:prev', data: prev });

          if (!subscriptionData.data) return prev;
          const { onBoardUpsert: {
            id,
            status,
            created,
            modified,
            name,
            order,
            template,
            sessionId,
            groupingCriteria
          } } = subscriptionData.data;

          const prevItems = (prev.boardList || { items: [] });
          const updateIndex = prevItems.items.findIndex((f) => f.id === id);

          let update: any;
          if (updateIndex >= 0) {
            prev.boardList.items[updateIndex] = {
              ...prev.boardList.items[updateIndex],
              name,
              status,
              order,
              modified,
              template,
              groupingCriteria,
              __typename: 'board'
            };
            update = { items: [ ...prev.boardList.items ] };
          } else {
            update = {
              items: [ ...prevItems.items, {
                id,
                created,
                modified,
                status,
                sessionId,
                name,
                order,
                template,
                groupingCriteria,
                __typename: 'board'
              }]
            }
          }

          return {
            boardList: {
              ...update,
              __typename: 'BoardList'
            }
          };
        }
      });

    }

    return function cleanup() {
      log.verbose({ action: 'sessionContext:useEffect:boardSubscription:cleanup' });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boards.loading])

  const createSession = async ({ name }: { name?: string }): Promise<Session> => {
    const createSessionOptions: any = {
      variables: {
        session: {
          name: name || 'New session'
        }
      }
    };
    log.verbose({ action: 'sessionContext:createSession:req', data: createSessionOptions });

    const newSession: any = await sessionCreate(createSessionOptions)
      .catch((err) => {
        setError(err.toString());
        log.error({ action: 'sessionContext:createSession', error: err });
        return {}
      });

    log.debug({ action: 'sessionContext:createSession:resp', data: newSession });
    return newSession.data.sessionCreate;
  }

  const updateSession = async (updateParams: { name?: string, status?: Status, presentedBoard?: string }): Promise<void> => {
    if (!currentSession) {
      throw new Error('Current session not set');
    }

    const updateSessionOptions: any = {
      variables: {
        session: {
          ...updateParams,
          id: currentSession.id
        }
      }
    };

    log.verbose({ action: 'sessionContext:updateSession:req', data: updateSessionOptions });

    const updateSession: any = await sessionUpdate(updateSessionOptions)
      .catch((err) => {
        log.error({ action: 'sessionContext:updateSession', error: err });
        return {}
      });

    log.debug({ action: 'sessionContext:updateSession:resp', data: updateSession });

    return;
  }

  const getSession = ({ id, ref }: { id?: string, ref?: string }) => {
    log.debug({ action: 'sessionContext:getSession:req', data: { id, ref } });

    if (id) {
      return getById({ variables: { sessionId: id } })
    }

    if (ref) {
      return getByRef({ variables: { ref } })
    }

    throw new Error('getSession requires either id or ref to be passed');
  }

  const setParticipant = async (name): Promise<Participant> => {
    setLoading(true);
    let participant;
    try {
      const resp = await setParticipantSession({
        variables: {
          participant: {
            name,
            sessionId: currentSession && currentSession.id
          }
        }
      });

      log.debug({ action: 'setParticipant:resp', data: resp.data.participantCreate });
      localStorage.setItem('participant', JSON.stringify(resp.data.participantCreate));
      setCurrentParticipant(resp.data.participantCreate)

      /*const resp2 = await setClientParticipantSession({ variables: { participant: resp.data.participantCreate } });
      localStorage.setItem('participantSession', JSON.stringify(resp2.data.setParticipant));*/
      // log.debug({ action: 'setParticipantSession:resp', data: resp2.data.setParticipant });

      setLoading(false);
      participant = resp.data.participantCreate;
    } catch (e) {
      console.error(e.toString());
      setError(e.toString());
    }

    setLoading(false);
    return participant;
  }

  const values = {
    loading: loading || loadingByRef || loadingById || loadingParticipants,
    error: error || (errorByRef ? errorByRef.message : ''),
    currentSession,
    currentSessionId,
    presentingBoardId,
    createSession,
    updateSession,
    getSession,
    sessionBoards: boards && boards.data && boards.data.boardList && boards.data.boardList.items,
    participant: currentParticipant,
    setParticipant,
    participants: (participants && participants.participants) || []
  };

  return (
    <SessionContext.Provider value={values}>
      {children}
    </SessionContext.Provider>
  );
}
