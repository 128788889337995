import gql from 'graphql-tag';

export const M_SET_PARTICIPANT = gql`mutation SetParticipant($participant: ParticipantCreateInput!){
  participantCreate(input: $participant) {
    id
    sessionId
    status
    name
  }
}`;

export const MC_SET_PARTICIPANT = gql`mutation SetClientParticipant($participant: ParticipantInput!){
  setParticipant(participant: $participant) @client {
    id
    sessionId
    status
    name
  }
}`;

export const M_CREATE_NOTE = gql`mutation NotesCreate($note: NoteCreateInput!){
  noteCreate(input: $note) {
    id
    boardId
    status
    created
    modified
    description
    group
    tags
    participant {
      id
      user {
        id
        name
      }
      name
    }
  }
}`;

export const M_UPDATE_NOTE = gql`mutation NotesUpdate($note: NoteUpdateInput!){
  noteUpdate(input: $note) {
    id
    boardId
    status
    created
    modified
    description
    group
    tags
    participant {
      id
      user {
        id
        name
      }
      name
    }
  }
}`;

export const M_CREATE_BOARD = gql`mutation CreateBoard($input: BoardCreateInput!) {
  boardCreate(input: $input) {
    id
    created
    modified
    status
    name
    order
    template
    sessionId
    groupingCriteria {
      id
      name
      order
      color
    }
  }
}`;

export const M_UPDATE_BOARD = gql`mutation UpdateBoard($input: BoardUpdateInput!) {
  boardUpdate(input: $input) {
    id
    created
    modified
    status
    name
    order
    template
    sessionId
    groupingCriteria {
      id
      name
      order
      color
    }
  }
}`;

export const M_SESSION_CREATE = gql`mutation SessionsCreate($session: SessionCreateInput!)
{
  sessionCreate(input: $session) {
    id
    ref
  }
}`;

export const M_SESSION_UPDATE = gql`mutation SessionUpdate($session: SessionUpdateInput!){
  sessionUpdate(input: $session) {
    id
    created
    modified
    status
    ref
    name
    presentedBoard
  }
}`;

export const M_SET_CURRENT_SESSION = gql`mutation SetCurrentSession($session: SessionCreateInput!){
  setSession(session: $session) @client {
    id
    ref
    boards {
      id
      status
    }
  }
}`;

export const M_PRESENT_BOARD = gql`mutation boardPresent($boardId: ID!, $sessionId: ID!) {
  boardSetPresenting(boardId: $boardId, sessionId: $sessionId) {
    id
    status
  }
}`;
