import React, { useContext } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import SessionHost from './pages/SessionHost/SessionHost';
import AuthenticationPage from './pages/authentication/authentication.page';
import './App.css';
import { SessionProvider } from './contexts/SessionContext';
import { BoardProvider } from './contexts/BoardContext';
import SessionPathSelect from './pages/SessionPathSelect/SessionPathSelect';
import SessionPresent from './pages/SessionPresent/SessionPresent';
import SessionParticipate from './pages/SessionParticipate/SessionParticipate';
import SessionCreate from './pages/SessionCreate/SessionCreate';
import Home from './pages/home/Home';
import { AuthContext, AuthProvider } from './contexts/AuthContext';
import { SidebarProvider } from './contexts/SidebarContext';
import { DebugProvider } from './contexts/DebugContext';

const App = () => {
  const { isAuthenticated } = useContext(AuthContext);
  return (
    <DebugProvider>
      <AuthProvider>
        <SessionProvider>
          <BoardProvider>
            <SidebarProvider>
              <Switch>
                <Route
                  exact={true}
                  path='/host'
                  render={(routeProps) => <SessionHost id={routeProps.match.params.id}/>}
                />
                <Route
                  exact={true}
                  path='/join/:ref'
                  component={Home}
                />
                <Route
                  exact={true}
                  path='/sign-in'
                  render={() => isAuthenticated ? (<Redirect to='/'/>) : (<AuthenticationPage/>)}
                />
                <Route
                  exact={true}
                  path='/session'
                  component={SessionCreate}
                />
                <Route
                  exact={true}
                  path='/session/:id'
                  render={(routeProps) => <SessionPathSelect id={routeProps.match.params.id}/>}
                />
                <Route
                  exact={true}
                  path='/session/:id/path-select'
                  render={(routeProps) => <Redirect to={`/session/${routeProps.match.params.id}`}/>}
                />
                <Route
                  exact={true}
                  path='/session/:id/participate'
                  render={(routeProps) => <SessionParticipate id={routeProps.match.params.id}/>}
                />
                <Route
                  exact={true}
                  path='/session/:id/present'
                  render={(routeProps) => <SessionPresent id={routeProps.match.params.id}/>}
                />
                <Route
                  exact={true}
                  path='/session/:id/host'
                  render={(routeProps) => <SessionHost id={routeProps.match.params.id}/>}
                />
                <Route
                  exact={true}
                  path='/'
                  component={Home}
                />
              </Switch>
          </SidebarProvider>
          </BoardProvider>
        </SessionProvider>
      </AuthProvider>
    </DebugProvider>
  );
};

export default App;
