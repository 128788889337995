import React, { useContext, useState } from 'react';
import { Board, Note } from '../../typings';
import './board.scss';
import { BoardContext } from '../../contexts/BoardContext';
import MindMap from './MindMap';
import NoteForm from './noteForm';
import Debugger from '../debug/debugger';
// import Log from '../../libs/log';

// const log = Log({ source: 'components:board' });

interface BoardComponentProps {
  initialBoard?: Board
}
const BoardComponent = ({ initialBoard }: BoardComponentProps) => {
  const { presentingBoard, currentBoard, boardNotes, categoryMap } = useContext(BoardContext);
  const [selected, setSelected] = useState<any>()

  const board = currentBoard || presentingBoard;

  // log.verbose({ action: 'boardComponent:load', data: { boardNotes } });

  if (!board) {
    return (<p>Start by selecting a board.</p>);
  }

  return (
    <div className='board'>

      <h1>{board.name}</h1>

      <Debugger name='Board' value={board} />

      <br/>

      <MindMap
        name={board.name}
        data={boardNotes ? boardNotes
          .filter(f => f.status === 'active')
          .map((note: Note) => ({
            key: note.id,
            group: { ...categoryMap(note.group) },
            description: note.description,
          })) : []}
        keyAccessor={d => d.key}
        onNodeSelect={d => {
          console.log('onNodeSelect(d)', d);
          const found = boardNotes.find((f) => f.id === d.key)
          console.log('found', found);
          setSelected(found);
        }}
      />

      <br/>

      {
        selected ? <NoteForm note={selected} onDone={() => { setSelected(null); }} /> : null
      }
    </div>
  );

}

export default BoardComponent;
