import gql from 'graphql-tag';
import { Q_GET_CURRENT_SESSION, Q_GET_PARTICIPANT, Q_GET_SESSION_USER } from './queries';
import Log from '../libs/log';
const log = Log({ source: 'graphql:resolvers' });

export const typeDefs = gql`
  directive @client on FIELD

  type SessionUser {
    sub: ID!
    username: String
  }

  input SessionUserInput {
    sub: ID!
    username: String
  }

  extend type Participant {
    key: String
  }

  input ParticipantInput {
    name: String!
    key: String!
  }

  extend type Mutation {
    setUser(user: SessionUserInput!): SessionUser!
    setParticipant(participant: ParticipantInput!): Participant!
    setSession(session: SessionCreateInput!): Session!
    boardSetPresenting(boardId: ID!, sessionId: ID!): Board
  }

  extend type Query {
    currentUser: SessionUser!
    currentSession: Session!
    participant: Participant!
  }

`;

export const resolvers = {
  Mutation: {
    setUser: (_root, { user }, { cache }) => {

      log.verbose({ action: 'resolvers:mutation:setUser', data: { user } });

      const { sub, username } = user;

      cache.writeQuery({
        query: Q_GET_SESSION_USER,
        data: { currentUser: { sub, username, __typename: 'user' } }
      });

      log.debug({ action: 'resolvers:mutation:setUser:resp', data: { sub, username } });
      return { sub, username, __typename: 'user' };
    },
    setParticipant: (_root, input, { cache }) => {
      log.verbose({ action: 'resolvers:mutation:setParticipant', data: { input } });

      const { participant } = input;
      cache.writeQuery({
        query: Q_GET_PARTICIPANT,
        data: { participant }
      });

      return participant;
    },
    setSession: (_root, input, { cache }) => {
      log.verbose({ action: 'resolvers:mutation:setSession', data: { input } });

      const { session } = input;
      cache.writeQuery({
        query: Q_GET_CURRENT_SESSION,
        data: { currentSession: session }
      });

      return session;
    }
  }
};
