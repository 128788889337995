import React, { useContext, useEffect } from 'react';
import CustomButton from '../custom-button/custom-button.component';
import FormInput from '../form-input/form-input.component';
import Spinner from '../../components/spinner/spinner.component';
import useInputState from '../../hooks/useInputState';
import { Form } from 'react-bootstrap';
import { SessionContext } from '../../contexts/SessionContext';
import { withRouter } from 'react-router-dom';

interface StartViewerContainerProps {
  history: any;
  match: any;
  location: any;
}

const SessionJoin = ({ history }: StartViewerContainerProps) => {
  const { loading, currentSession, getSession, error } = useContext(SessionContext);
  const [participantKey, updateParticipantKey] = useInputState('');

  // Redirect when session is set
  useEffect(() => {
    if (currentSession && currentSession.id) {
      history.push(`/session/${ currentSession.id }/path-select`);
    }
  }, [currentSession, history]);

  if ((loading || currentSession) && !error) {
    return (<Spinner message={'Joining session'}/>);
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  const handleStartSession = (event: React.ChangeEvent<HTMLInputElement> | React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    getSession({ ref: participantKey });
  };

  return (
    <Form onSubmit={handleStartSession} className='start-particpate'>
      <h1 className='title'>Join a session</h1>
      <h4>Please enter your session code</h4>
      <FormInput
        type='text'
        name='participantKey'
        value={participantKey}
        handleChange={updateParticipantKey}
        label='session code'
      />

      {error ? <div className='error'>{error}</div> : null}

      <CustomButton onClick={handleStartSession} size='lg' block={true}>Join session</CustomButton>
    </Form>
  );
};

export default withRouter(SessionJoin);
