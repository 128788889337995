import React, { createContext, useCallback, useEffect, useState } from 'react';
import { Auth, Hub } from 'aws-amplify';
import { Auth as authUtils } from '../amplify/amplify.utils';
import Log from '../libs/log';
import { AppScopes } from '../shared/enums';

type AuthUserType = {
  id: string;
  email: string;
  phone?: string;
  name?: string;
  scopes: AppScopes[];
};
type AuthContextType = {
  authenticating: boolean;
  isAuthenticated: boolean;
  refreshSession: any;
  user: AuthUserType | undefined;
  hasScope({ scope }: { scope: AppScopes }): boolean;
};
type AuthContextProps = {
  children: React.ReactNode;
};

const log = Log({ source: 'contexts:auth' });

export const AuthContext = createContext({} as AuthContextType);

export function AuthProvider({ children }: AuthContextProps): any {
  const [user, setUser] = useState<AuthUserType>();
  const [authenticating, setAuthenticating] = useState(true);
  const [isAuthenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    const authUtil: any = authUtils();
    authUtil.getAuthenticatedUser()
      .then((data: any) => {
        if (data.username === 'anon@remotethinking.com') {
          log.verbose({
            action: 'authContext:getAuthenticatedUser:resp',
            message: 'Setting user to anonymous'
          });
          // setCognitoUser({ username: 'Anonymous user' });
          setUser({ username: 'Anonymous user', scopes: [] } as any);
          setAuthenticated(false);
          setAuthenticating(false);
          return;
        }
        // setCognitoUser({ sub: data.attributes.sub, username: data.username });
      })
      .catch(async () => {
        // sign in as anonymous user
        log.verbose({
          action: 'authContext:getAuthenticatedUser:error',
          message: 'Signing in anonymously'
        });
        try {
          await authUtil.SignInAnonymous();
          log.verbose({
            action: 'authContext:getAuthenticatedUser:error:SignInAnonymous',
            message: 'Signed in anonymously'
          });
        } catch (e) {
          // TODO: Set global error for users not signed in.
          log.error({
            action: 'authContext:getAuthenticatedUser:error:SignInAnonymous:error',
            error: e
          });
          // setCognitoUser({ username: '' });
        }
      });
  }, [])

  function sanitizeUser(data: any): AuthUserType | undefined {
    if (!data) {
      return;
    }

    return {
      id: data.attributes.sub as string,
      email: data.attributes.email,
      scopes: [AppScopes.DEBUG]
    };
  }

  function clearSession() {
    setUser(undefined as any);
    setAuthenticated(false);
  }
  const refreshSession = useCallback(async (): Promise<AuthUserType | undefined> => {
    log.verbose({ action: 'authContext:refreshSession' });
    setAuthenticating(true);
    const currentUser = await Auth.currentUserInfo();
    log.verbose({ action: 'authContext:refreshSession:currentUser', data: currentUser });

    if (currentUser && currentUser.attributes.sub !== '10d015b2-8109-4035-b5d1-70bf278a36dd') {
      log.verbose({ action: 'authContext:refreshSession:is-signed-in' });
      // Signed in
      if (user !== currentUser) {
        log.verbose({ action: 'authContext:refreshSession:is-signed-in:new-user' });
        setUser(sanitizeUser(currentUser) as any);
        setAuthenticated(true);
      }

      setAuthenticating(false);

      log.verbose({ action: 'authContext:refreshSession:is-signed-in' });
      return user;
    }

    // User logout
    if (user) {
      clearSession();
    }

    setAuthenticating(false);

    return user;
  }, [user]);

  useEffect(() => {
    Hub.listen('auth', async ({ payload }) => {
      log.debug({ action: 'authContext:hubEvent:signIn', data: payload });

      if (payload.event === 'signIn') {
        log.debug({ action: 'authContext:hubEvent:signIn', data: '' });
        // Add missing attributes for forced password resets.
        const attributes = payload.data.attributes || (await Auth.currentUserInfo()).attributes;

        setUser(sanitizeUser({ attributes }) as any);
        setAuthenticated(true);
      }

      if (payload.event === 'signOut') {
        log.debug({ action: 'authContext:hubEvent:signOut', data: '' });
        clearSession();
      }
    });
  }, []);

  useEffect(() => {
    if (!isAuthenticated) {
      refreshSession()
        .then((currentUser) => {
          log.debug({ action: 'authContext:refreshSession', data: currentUser });
          // console.log('Initial user', currentUser);
        })
        .catch((err) => {
          // console.error(err.toString());
          log.debug({ action: 'authContext:refreshSession', data: err.toString() });
        });
    }
    // eslint-disable-next-line
  }, []);

  const hasScope = ({ scope }: { scope: AppScopes }) => {
    if (!user) {
      return false;
    }
    return (user || { scopes: [] }).scopes.includes(scope);
  }

  return (
    <AuthContext.Provider value={{ authenticating, isAuthenticated, user, refreshSession, hasScope }}>
      {children}
    </AuthContext.Provider>
  )
}

export const withAuthContext = (Component: any) => (props: any) => (
  <AuthContext.Consumer>
    {value => <Component authContext={value} {...props} />}
  </AuthContext.Consumer>
);
