import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from 'react-apollo';
import { SessionContext } from './SessionContext';
import { Q_NOTES } from '../graphql/queries';
import { M_CREATE_BOARD, M_UPDATE_BOARD } from '../graphql/mutations';
import { SUBSCRIPTION_NOTES } from '../graphql/subscriptions';
import Log from '../libs/log';
import { Board, BoardCategory, BoardCreate, BoardUpdate } from '../typings';
import useToggle from '../hooks/useToggle';
import { v4 } from 'uuid'

type BoardContextType = {
  loading: boolean,
  currentBoard: Board | undefined,
  presentingBoard: Board | undefined,
  creatingBoard: boolean,
  toggleCreatingBoard(): void,
  editingBoard: boolean,
  toggleEditingBoard(): void,
  setBoard: ({ id }: { id: string }) => void,
  addBoard: (board: BoardCreate) => void,
  editBoard: (board: BoardUpdate) => void,
  presentBoard: ({ id }: { id: string }) => void,
  presentCurrentBoard: () => void,
  boardNotes: any | undefined,

  categories: BoardCategory[],
  categoriesAdd(category?: BoardCategory): void,
  categoriesRemove({ id: string }): void,
  categoriesReorder({ oldIndex, newIndex }: { oldIndex: number, newIndex: number }): void,
  categoryUpdate(category: BoardCategory): void,
  categoryMap(id): BoardCategory
};
type BoardContextProps = {
  children: React.ReactNode;
};

const log = Log({ source: 'contexts:board' });

export const BoardContext = createContext({} as BoardContextType);

export function BoardProvider({ children }: BoardContextProps): any {
  const { currentSession, updateSession, sessionBoards, presentingBoardId } = useContext(SessionContext);
  const [currentBoard, setBoard] = useState<Board>();
  const [presentingBoard, setPresentingBoard] = useState<Board>();
  const [editingBoard, toggleEditingBoard] = useToggle(false);
  const [creatingBoard, toggleCreatingBoard] = useToggle(false);
  const [createBoard] = useMutation(M_CREATE_BOARD);
  const [updateBoard] = useMutation(M_UPDATE_BOARD);
  const [getBoardNotes, { loading: loadingBoardNotes, data: boardNotes, subscribeToMore: subscribeToNotes }] =
    useLazyQuery(Q_NOTES, {
      fetchPolicy: 'network-only'
    });
  const [categories, updateCategories] = useState<BoardCategory[]>([]);

  log.debug({ action: 'boardContext:load', data: {
      loadingBoardNotes,
      boardNotes,
      subscribeToNotes
    } })

  // set the presenting board
  // SessionBoards changes are tracked as we wait for the board list on init
  // presentingBoardId is tracked to set the new board
  useEffect(() => {
    log.debug({ action: 'boardContext:useEffect:changePresentingBoard', data: { sessionBoards, presentingBoardId } });

    if (sessionBoards) {

      if (presentingBoardId) {
        log.verbose({ action: 'boardContext:useEffect:changePresentingBoard:presentingBoard', data: { id: presentingBoardId } });
        try {
          const board = sessionBoards.find((f) => f.id === presentingBoardId);
          log.verbose({ action: 'boardContext:useEffect:changePresentingBoard:old-and-new', data: { old: presentingBoard, new: board } });

          setPresentingBoard(board);
        } catch (err) {
          log.error({ action: 'boardContext:useEffect:changePresentingBoard:presentingBoard:error', error: err });
        }
      }

      if (currentBoard) {
        try {
          const board = sessionBoards.find((f) => f.id === currentBoard.id);
          log.verbose({ action: 'boardContext:useEffect:changeCurrentBoard:old-and-new', data: { old: currentBoard, new: board } });

          setBoard(board);
        } catch (err) {
          log.error({ action: 'boardContext:useEffect:changeCurrentBoard:currentBoard:error', error: err });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionBoards, presentingBoardId])

  // subscribe to board notes
  useEffect(() => {
    log.debug({ action: 'boardContext:useEffect:boardNotes', data: { boardNotes } });

    // don't subscribe while loading
    if (!subscribeToNotes || loadingBoardNotes) {
      return;
    }

    if (!(currentBoard || presentingBoard)) {
      // todo: unsubscribe from notes if previously subscribed
      log.verbose({ action: 'boardContext:useEffect:boardNotesSubscription:no-current-or-presented-board' });
      return;
    }

    const boardId = (currentBoard || presentingBoard || { id: '' }).id;

    if (boardId && subscribeToNotes) {
      log.verbose({action: 'boardContext:useEffect:boardNotesSubscription:subscribing', data: { boardId }});
      subscribeToNotes({
        document: SUBSCRIPTION_NOTES,
        variables: { boardId },
        updateQuery: (prev, {subscriptionData, variables}) => {
          log.verbose({
            action: 'boardContext:useEffect:boardNotesSubscription:subscribeToMore:change',
            data: {subscriptionData, variables}
          });
          log.verbose({action: 'boardContext:useEffect:boardNotesSubscription:subscribeToMore:prev', data: prev });

          if (!subscriptionData.data) return prev;
          const { onNoteUpsert: {
            id,
            status,
            modified,
            description,
            tags,
            ...noteParams
          } } = subscriptionData.data;

          const prevItems = (prev.notes || []);
          const updateIndex = prevItems.findIndex((f) => f.id === id);

          let update: any;
          if (updateIndex >= 0) {
            prev.notes[updateIndex] = {
              ...prev.notes[updateIndex],
              status,
              modified,
              description,
              tags,
              __typename: 'note'
            };
            update = [ ...prev.notes ];
          } else {
            update = [ ...prevItems, {
              ...noteParams,
              id,
              status,
              modified,
              description,
              tags,
              __typename: 'note'
            }]
          }

          log.verbose({action: 'boardContext:useEffect:boardNotesSubscription:subscribeToMore:latest', data: update });

          return {
            notes: update
          };
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boardNotes]);

  // get current board notes
  useEffect(() => {
    log.debug({ action: 'boardContext:useEffect:currentBoardNotes', data: { currentBoard } });

    if (!currentBoard) {
      log.verbose({ action: 'boardContext:useEffect:currentBoardNotes:no-current-board' });
      return;
    }

    const board = currentBoard || { id: '' };

    log.verbose({ action: 'boardContext:useEffect:currentBoardNotes:getBoardNotes', data: { variables: { boardId: board.id } } });
    getBoardNotes({
      variables: { boardId: board.id }
    })

    // set categories
    updateCategories((currentBoard || { groupingCriteria: [] }).groupingCriteria)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBoard]);

  // get presenting board notes
  useEffect(() => {
    log.debug({ action: 'boardContext:useEffect:presentingBoardNotes', data: { presentingBoardId } });

    if (!presentingBoardId) {
      log.verbose({ action: 'boardContext:useEffect:presentingBoardNotes:no-presenting-board' });
      return;
    }

    const board = { id: presentingBoardId || '' };

    log.verbose({ action: 'boardContext:useEffect:boardNotes:getNotes', data: { variables: { boardId: board.id } } });
    getBoardNotes({
      variables: { boardId: board.id }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [presentingBoardId]);

  const addBoard = (board: BoardCreate): void => {
    log.verbose({ action: 'boardContext:addBoard:req', data: { variables: { input: board } } });

    if (!currentSession) {
      throw new Error('Session not started');
    }

    createBoard({
      variables: { input: { ...board, sessionId: currentSession.id } }
    }).catch((err) => {
      log.error({ action: 'boardContext:addBoard:error', error: err });
    })
  }

  const prepGQLObject = (obj: any) => {
    console.log({ action: 'prepGQLObject:req', type: typeof obj, obj });
    if (typeof obj === 'object') {
      if (obj.length) {
        console.log({ action: 'prepGQLObject:array' });
        const t: any[] = obj.map((m) => prepGQLObject(m));

        console.log({ action: 'prepGQLObject:resp', data: t });
        return t;
      } else {
        console.log({ action: 'prepGQLObject:object' });
        const t: any = Object
          .keys(obj)
          .filter((f) => f !== '__typename')
          .reduce((memo, cur) => {
            memo[cur] = prepGQLObject(obj[cur]);
            return memo;
          }, {});

        console.log({ action: 'prepGQLObject:resp', data: t });
        return t;
      }
    }

    console.log({ action: 'prepGQLObject:non-object' });
    console.log({ action: 'prepGQLObject:resp', obj });
    return obj

  }

  const editBoard = (board: BoardUpdate): void => {
    log.verbose({ action: 'boardContext:editBoard:req', data: { variables: { input: board } } });

    if (!currentSession) {
      throw new Error('Session not started');
    }

    if (!currentBoard) {
      throw new Error('Board not selected');
    }

    updateBoard({
      variables: { input: prepGQLObject({ ...board, id: currentBoard.id }) }
    }).catch((err) => {
      log.error({ action: 'boardContext:editBoard:error', error: err });
    })
}

  const setCurrentBoard = ({ id }: { id: string }) => {
    log.verbose({ action: 'boardContext:setCurrentBoard:req', data: { id } });
    try {
      const board = sessionBoards.find((f) => f.id === id);
      setBoard(board);
    } catch (err) {
      log.error({ action: 'boardContext:setCurrentBoard:error', error: err });
    }
    // subscribeToNotes({ boardId: board.id });
  }

  const presentCurrentBoard = async () => {
    if (!currentBoard) {
      throw new Error('Current board not set');
    }

    return presentBoard({ id: currentBoard.id })
  }

  const presentBoard = async ({ id }: { id: string }) => {
    log.verbose({ action: 'boardContext:presentCurrentBoard:req', data: { id } });
    try {
      await updateSession({ presentedBoard: id });
    } catch (err) {
      log.error({ action: 'boardContext:presentCurrentBoard:error', error: err });
    }
  }

  // Categories

  const categoriesAdd = (category?: BoardCategory): void => {
    updateCategories([
      ...categories,
      {
        color: '#9f4c4c',
        name: '',
        order: categories.length,
        ...category || {},
        id: v4()
      }
    ])
  }

  const categoriesRemove = ({ id }: { id: string }): void => {
    updateCategories(categories.filter(f => f.id !== id))
  }

  const categoriesReorder = ({ oldIndex, newIndex }: { oldIndex: number, newIndex: number }) => {
    const newCategories = categories;
    const item = newCategories.splice(oldIndex, 1)[0];
    newCategories.splice(newIndex, 0, item);
    updateCategories(newCategories);
  };

  const categoryUpdate = (category: BoardCategory): void => {
    updateCategories(categories
      .map((m) => m.id === category.id ? category : m))
  }

  const categoryMap = (id): BoardCategory => {
    return (((presentingBoard || {})
      .groupingCriteria || [])
      .find(f => f.id === id) || { id, name: 'unknown', order: 1, color: '#123123' })
  }

  return (
    <BoardContext.Provider
      value={{
        loading: loadingBoardNotes,
        currentBoard,
        addBoard,
        editBoard,
        presentingBoard,
        creatingBoard,
        toggleCreatingBoard,
        editingBoard,
        toggleEditingBoard,
        presentBoard,
        presentCurrentBoard,
        boardNotes: (boardNotes || { notes: [] }).notes,
        setBoard: setCurrentBoard,
        categories,
        categoriesAdd,
        categoriesRemove,
        categoriesReorder,
        categoryUpdate,
        categoryMap
      }}
    >
      {children}
    </BoardContext.Provider>
  );
}
